import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { outlineRemove } from "utility/helper";
import { useParams } from "react-router-dom";

const BenchmarkModel = ({ updateBenchmarklisting, ...props }) => {
    const [editData, setEditData] = useState({});
    const [reportTypes, setReportTypes] = useState([]);
    const { register, handleSubmit, errors } = useForm();
    const [selectedReportType, setSelectedReportType] = useState(null);
    const [benchmarkType] = useState([{value: 'customized_benchmark', label: 'Customized Benchmark'}]);
    const urlParams = useParams();

    /**
     * Updates the modal form data with a new value for a given field.
     *
     * @param {string} name - The name of the field to update.
     * @param {any} value - The new value for the field.
     */
    const changeModalFormData = (name, value) => {
        setEditData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    
    /**
     * Handles the input change event.
     *
     * @param {object} e - The event object.
     * @return {void}
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        changeModalFormData(name, value);
    };
    
    /**
     * Retrieves the report types from the server and sets them in the component state.
     *
     * @return {void} This function does not return any value.
     */
    const getReportTypes = () => {
        let formData = new FormData();
        formData.append("report_type", "partner");
        Axios.post(configURL.getReportTypes, formData).then(response => {
            if (response.data.success) {
                let typesData = response.data.results.map(element => ({
                    value: element.id,
                    label: element.name
                }));
                setReportTypes(typesData);
            }
        });
    };

    /**
     * Submits the form data to the server and performs necessary actions based on the response.
     *
     * @param {Object} data - The form data to be submitted.
     * @return {void}
     */
    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("benchmark_id", editData.id || "");
        formData.append("description", data.description);
        formData.append("name", data.name);
        formData.append("type", "customized_benchmark");
        formData.append("report_type_id", selectedReportType?.value || reportTypes.find(option => option.label === props?.editdata?.report_type_name)?.value);
        formData.append("report_type_name", selectedReportType?.label || reportTypes.find(option => option.label === props?.editdata?.report_type_name)?.label);
        formData.append("account_id", urlParams?.account_id);
        const url = editData.id ? configURL.update_benhmark : configURL.create_benchamark;
        Axios.post(url, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                toast.success(res.data.message || 'Benchmark updated successfully.');
                updateBenchmarklisting();
                props.onHide();
            } else {
                toast.warn(res.data.message || 'Something went wrong.');
            }
        }).catch(error => {
            toast.error('Error updating benchmark. Please try again.');
            console.error(error);
        });
    };

    useEffect(() => {
        if (!props.show) {
            setEditData({});
            setSelectedReportType(null);
            return;
        }
        if (props.editdata) {
            setEditData(props.editdata);
            getReportTypes();
            setSelectedReportType(reportTypes.find(option => option.label === props.editdata.report_type_name));
        } else {
            getReportTypes();
        }
    }, [props.show]);

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="benchmark-modal-wrapper">
            <Modal.Header className="ezi-modal-header">
                <Modal.Title id="contained-modal-title-vcenter" className="benchmark-modal-title ezi-modal-header-title">
                    <span className="benchmark-modal-title-text">{editData.id ? 'Update Benchmark' : 'Add New Benchmark'}</span>
                    <span className="ezi-modal-close" onClick={props.onHide}></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} className="add-benchmark-form">
                    <div className="benchmark-field-wrapper">
                        <div className="benchmark-field-50">
                            <div className="benchmark-field">
                                <label>Benchmark name</label>
                                <input
                                    type="text"
                                    className="benchmark-field-control"
                                    name="name"
                                    maxLength={100}
                                    ref={register({ required: true })}
                                    value={editData.name || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter Benchmark Name"
                                />
                                {errors.name && <span className="benchmark-error_cu">Benchmark name is required.</span>}
                            </div>
                            <div className="benchmark-field">
                                <label>Benchmark type</label>
                                    <Select
                                    className="benchmark-field-control p-0"
                                    ref={register}
                                    options={benchmarkType}
                                    value={benchmarkType[0]}
                                    styles={outlineRemove}
                                    placeholder="Please select Benchmark type"
                                    // onChange={(value) => {
                                    //     setReportType(value);
                                    //     setSelectedReportType(value);
                                    // }}
                                    isDisabled
                                />
                            </div>
                            <div className="benchmark-field">
                                <label>Report type</label>
                                <Select
                                    className="benchmark-field-control  pl-0"
                                    ref={register}
                                    options={reportTypes}
                                    value={selectedReportType || reportTypes.find(option => option.label === props?.editdata?.report_type_name)}
                                    styles={outlineRemove}
                                    placeholder="Please Select Report Type"
                                    onChange={(value) => {
                                        setSelectedReportType(value);
                                    }}
                                />
                            </div>
                            <div className="benchmark-field">
                                <label>Description</label>
                                <textarea
                                    rows="4"
                                    cols="50"
                                    className="benchmark-field-control"
                                    name="description"
                                    ref={register}
                                    value={editData.description || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter Description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="benchmark-modal-footer">
                        <button type="button" className="close-benchmark-btn" onClick={props.onHide}>Close</button>
                        <input type="submit" value={editData.id ? 'Update' : 'Save'} className="btn-ripple ezi-pink-btn add-benchmark-btn" />
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
};
export default BenchmarkModel;


