import React, { useState, useEffect, useRef, useContext } from "react";
import './QuestionBank.scss';
import { Breadcrumb, Modal } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import useOutsideClick from "hooks/useOutsideClick";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from "utility/Axios";
import configURL from 'config/config';
import AppContext from 'store/AppContext';
import SweetSearch from "components/SweetSearch";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { outlineRemove, uniqueGenerator } from "utility/helper";
import { confirmAlert } from 'react-confirm-alert';
import useForm from 'react-hook-form';
import { useNavigate } from "react-router-dom";

const QuestionBank = ({ props }) => {
    const { EziLoader, languageObj = {} } = useContext(AppContext)
    const ref = useRef();
    const [dropdownFlag, setDropdownFlag] = useState(false)
    const [category, setCategory] = useState({});
    const [categoryDs, setCategoryDs] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [themes, setThemes] = useState([]);
    const [tableMeta, setTableMeta] = useState({
        pagination: {},
        columns: [{}],
        uniqueKey: "id"
    });
    const per_page = 10
    let searchTimer = null
    let inputSearch = useRef(null)
    const [searchLoading, setSearchLoading] = useState(false);
    const [dropdownThemeFlag, setDropdownThemeFlag] = useState(false);
    const [searchThemeTerm, setSearchThemeTerm] = useState('');
    const [theme, setTheme] = useState({ name: "All Themes", value: "" });
    const [themeSearchResults, setThemeSearchResults] = useState([]);
    const refTheme = useRef();
    const [questionThemes, setQuestionThemes] = useState([]);
    const [subQuestionThemes, setSubQuestionThemes] = useState([]);
    const [questionData, setQuestionData] = useState({ title: "", category_id: "", theme_id: "", scope: true });
    const [subQuestionData, setSubQuestionData] = useState({ title: "", category_id: "", theme_id: "", scope: true });
    const [modalType, setModalType] = useState("add-question");
    const [categorySelectValue, setCategorySelectValue] = useState(null);
    const [selectedValueForCompetency, setSelectedValueForCompetency] = useState(null);
    const [selectedValueForSubCompetency, setSelectedValueForSubCompetency] = useState(null);
    const { register, handleSubmit, errors } = useForm();
    const [showMenu, setShowMenu] = useState({ competency: false, subCompetency: false });
    const [status, setStatus] = useState({ error: false, value: null });
    const [subTheme, setSubTheme] = useState({ name: "All Sub-Themes", value: "" });
    const [dropdownSubThemeFlag, setDropdownSubThemeFlag] = useState(false);
    const [searchSubTheme, setSearchSubTheme] = useState('');
    const [subThemeSearchResults, setSubThemeSearchResults] = useState([]);
    const [subThemes, setSubThemes] = useState([]);
    const refSubTheme = useRef();
    const navigate = useNavigate();

    /**
     * Toggles the dropdown flag and resets the search term.
     *
     * @return {void} 
     */
    const dropdown = () => {
        setSearchTerm('');
        setDropdownFlag(!dropdownFlag);
    }

    /**
     * Resets the search term for themes and toggles the dropdown theme flag.
     *
     * @return {void} 
     */
    const dropdownThemes = () => {
        setSearchThemeTerm('');
        setDropdownThemeFlag(!dropdownThemeFlag);
    }

    /**
     * Toggles the dropdown sub-theme flag and resets the search term for sub-themes.
     *
     * @return {void} 
     */
    const dropdownSubThemes = () => {
        setSearchSubTheme('');
        setDropdownSubThemeFlag(!dropdownSubThemeFlag);
    }

    /**
     * Generates a JSX element representing the scope of a question.
     *
     * @param {any} cell - The value of the cell in the row.
     * @param {Object} row - The row object containing the data.
     * @return {JSX.Element} A span element with a class name based on the row's private status, displaying either "Private" or "Public".
     */
    const scopeFormatter = (cell, row) => {
        return (
            <span className={`question-status_c ${row.is_private === true ? "Private" : "Public"}`}>{row.is_private === true ? "Private" : "Public"}</span>
        );
    }

    /**
     * Returns a JSX element representing the status of a row.
     *
     * @param {any} cell - The value of the cell in the row.
     * @param {Object} row - The row object containing the data.
     * @return {JSX.Element} A span element with a class name based on the row's active status, displaying either "Active" or "Inactive".
     */
    const statusFormatter = (cell, row) => {
        return (
            <span className={`question-status_c ${row.active === true ? "Active" : "Inactive"}`}>{row.active === true ? "Active" : "Inactive"}</span>
        );
    }


/**
 * Updates the category, theme, sub-theme, and question data based on the provided category object.
 *
 * @param {Object} categoryObj - The category object to set.
 * @return {void}
 */
    const changeCategory = (categoryObj) => {
        setDropdownFlag(!dropdownFlag);
        setCategory(categoryObj);
        setTheme({ name: "All Themes", value: "" });
        setSubTheme({ name: "All Sub-Themes", value: "" });
        setQuestionData({ ...questionData, theme_id: "", sub_theme_id: "" });
        setCategorySelectValue(null);
        setSubQuestionData({ ...subQuestionData, theme_id: "" });
        handleThemeListing(categoryObj.id, 1);
    };

    /**
     * Toggles the dropdown theme flag, sets the theme object to the provided themeObj,
     * and sets the sub-theme object to "All Sub-Themes". If the themeObj name is not
     * "All Sub-Themes", it calls the handleSubThemesListing function with the themeObj id.
     *
     * @param {Object} themeObj - The theme object to set.
     * @return {void}
     */
    const changeTheme = (themeObj) => {
        setDropdownThemeFlag(!dropdownThemeFlag);
        setTheme(themeObj);
        setSubTheme({ name: "All Sub-Themes", value: "" });
        themeObj.name !== "All Sub-Themes" && handleSubThemesListing(themeObj.id);
    }

    /**
     * Toggles the dropdown sub-theme flag and sets the sub-theme object to the provided subThemeObj.
     *
     * @param {Object} subThemeObj - The sub-theme object to set.
     * @return {void}
     */
    const changeSubTheme = (subThemeObj) => {
        setDropdownSubThemeFlag(!dropdownSubThemeFlag);
        setSubTheme(subThemeObj);
    }

    /**
     * Sets the question bank listing based on the provided category object and page number.
     *
     * @param {Object} categoryObj - The category object to filter the question bank listing.
     * @param {number} [pageno=1] - The page number to fetch the question bank listing from. Defaults to 1.
     * @return {void} This function does not return anything.
     */
    const setQuestionBankListing = (categoryObj, pageno = 1) => {
        EziLoader.show()
        let formData = new FormData();
        formData.append("category_id", categoryObj.id);
        formData.append("page", pageno);
        formData.append("per_page", per_page);
        Axios.post(configURL.question_bank_list, formData).then(res => {
            EziLoader.hide()
            if (res.data.success !== undefined && res.data.success) {
                setQuestions(res.data.result);
                let columnData = res.data.columns
                columnData.filter((data, index) => {
                    return (data.dataField === "is_private" && data.text === 'Scope') ? data.formatter = scopeFormatter : "";
                })

                columnData.filter((data, index) => {
                    return (data.dataField === "active" && data.text === 'Status') ? data.formatter = statusFormatter : "";
                })
                const updatedColumnData = [
                    ...columnData,
                    {
                        dataField: "action",
                        text: "Action",
                        formatter: (cell, row) => (
                            <button className={`theme-delete`} onClick={() => handleDelete(row)} title="Delete">
                                
                            </button>
                        )
                    }
                ].filter(({ dataField }) =>
                    !["industry_name", "is_private", "active", "created_at"].includes(dataField)
                );

                setTableMeta({
                    pagination: res.data.pagination,
                    columns: updatedColumnData,
                    uniqueKey: res.data.unique_key || "id",
                })
            } else {
                setQuestions([]);
            }
        }).catch(err => {
            console.log(err);
            EziLoader.hide()
        })
    };

    /**
     * Search Table Handler
     */
    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getQuestions();
        }, 1000);
    }
    
    
/**
 * Fetches question data from the server based on the provided page number and search query.
 *
 * @param {number} [page=1] - The page number to fetch data from. Defaults to 1.
 * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
 */
    const getQuestions = (page = 1) => {
        EziLoader.show();
        let formData = new FormData()
        let search = inputSearch.current.value;
        formData.append("page", page);
        formData.append("search", search);
        formData.append("language", languageObj.curLang);
        formData.append("per_page", per_page);
        formData.append("category_id", category?.id);
        formData.append("theme_id", theme?.id || "");
        formData.append("sub_theme_id", subTheme?.id || "");
        Axios.post(configURL.question_bank_list, formData).then(res => {
            EziLoader.hide();
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                setQuestions(res.data.result);
                let columnData = res.data.columns
                columnData.filter((data, index) => {
                    return (data.dataField === "is_private" && data.text === 'Scope') ? data.formatter = scopeFormatter : "";
                })

                columnData.filter((data, index) => {
                    return (data.dataField === "active" && data.text === 'Status') ? data.formatter = statusFormatter : "";
                })
                const updatedColumnData = [
                    ...columnData,
                    {
                        dataField: "delete",
                        text: "Action",
                        formatter: (cell, row) => (
                            <button className={`theme-delete`} onClick={() => handleDelete(row)} title="Delete"></button>
                        )
                    }
                ].filter(({ dataField }) =>
                    !["industry_name", "is_private", "active", "created_at"].includes(dataField)
                );

                setTableMeta({
                    pagination: res.data.pagination,
                    columns: updatedColumnData,
                    uniqueKey: res.data.unique_key || "id",
                })
            } else {
                toast.warn(res.data.message || "Something went wrong")
                setQuestions([]);
            }
        }).catch(err => {
            EziLoader.hide()
            setSearchLoading(false);
            toast.error("Something went wrong")
        })
    }
    const searchCategory = (e) => {
        setSearchTerm(e.target.value);
    }

    /**
     * Updates the state with the value of the input element when the user types in the search bar for themes.
     *
     * @param {Event} e - The event object triggered by the input element.
     * @return {void} This function does not return anything.
     */
    const searchThemes = (e) => {
        setSearchThemeTerm(e.target.value);
    }

    /**
     * Updates the state with the value of the input element when the user types in the search bar for sub-themes.
     *
     * @param {Event} e - The event object triggered by the input element.
     * @return {void} This function does not return anything.
     */
    const searchSubThemes = (e) => {
        setSearchSubTheme(e.target.value);
    }

    useOutsideClick(ref, () => {
        dropdownFlag && setDropdownFlag(false);
    });

    useOutsideClick(refTheme, () => {
        dropdownThemeFlag && setDropdownThemeFlag(false);
    });

    useOutsideClick(refSubTheme, () => {
        dropdownSubThemeFlag && setDropdownSubThemeFlag(false);
    });

/**
 * Renders a remote paginated BootstrapTable component with the provided data, page number, size per page, 
 * table change event handler, and total number of items.
 *
 * @param {Object} props - The properties for the component.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {number} [props.page=1] - The current page number.
 * @param {number} props.sizePerPage - The number of items to display per page.
 * @param {Function} props.onTableChange - The event handler for table change events.
 * @param {number} props.totalSize - The total number of items in the table.
 * @return {JSX.Element} The rendered BootstrapTable component.
 */
    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={tableMeta.columns}
            noDataIndication="Statement Not Found"
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
            rowClasses="communication-template-datatable-row"
        />
    );

    /**
     * Updates the question bank listing based on the provided page number and size per page.
     *
     * @param {string} type - The type of change that occurred.
     * @param {object} options - The options object containing the page number and size per page.
     * @param {number} options.page - The page number to update the question bank listing to.
     * @param {number} options.sizePerPage - The size per page to update the question bank listing to.
     * @return {void}
     */
    const handleAllEntriesChange = (type, { page, sizePerPage }) => {
        if (type === 'pagination') {
            setQuestionBankListing(category, page);
        }
    }

    /**
     * Fetches the initial category data from the server.
     *
     * This function sends a POST request to the `configURL.partner_categoryDs` endpoint
     * with a `type` parameter set to `"template"`. It then handles the response by setting
     * the `categoryDs` state with the received data, selecting the first category object,
     * setting the `category` state, and calling the `handleThemeListing` function with the
     * category ID, page number, theme type, and status set to `"initial"`.
     *
     * @return {void}
     */
    const getInitialCatgory = () => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("type", "template");
        Axios.post(configURL.partner_categoryDs, formData).then(res => {
            if (res.data.result && res.data.result.length > 0) {
                setCategoryDs(res.data.result);
                let categoryObj = res.data.result[0];
                setCategory(categoryObj);
                handleThemeListing(categoryObj.id, 1, "template", "initial");
                EziLoader.hide();
            } else {
                EziLoader.hide();
            }
        }).catch(err => {
            EziLoader.hide();
            console.log(err);
        })
    }

    /**
     * Closes the modal and resets the state of related variables.
     *
     * @return {void}
     */
    const handleCloseModal = () => {
        setShowModal(false);
        setQuestionThemes([]);
        setSubQuestionThemes([]);
        setQuestionData({ title: "", category_id: "", theme_id: "", scope: true });
        setShowMenu({ competency: false, subCompetency: false });
        setModalType("add-question");
        setCategorySelectValue(null);
    }

    /**
     * Sets the state of `showModal` to true and resets the state of `dropdownFlag`, 
     * `dropdownThemeFlag`, and `dropdownSubThemeFlag` to false.
     *
     * @return {void}
     */
    const handleShowModal = () => {
        setShowModal(true)
        dropdownFlag && setDropdownFlag(false);
        dropdownThemeFlag && setDropdownThemeFlag(false);
        dropdownSubThemeFlag && setDropdownSubThemeFlag(false);
    };

    /**
     * Fetches a list of themes based on the provided category ID, page number, theme type, and status.
     *
     * @param {string} categoryId - The ID of the category.
     * @param {number} [pageno=1] - The page number to fetch themes from. Default is 1.
     * @param {string} [themeType="template"] - The type of theme to fetch. Default is "template".
     * @param {string} [status] - The status of the theme. Default is undefined.
     * @return {void}
     */
    const handleThemeListing = (categoryId, pageno = 1, themeType = "template", status) => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("category_id", categoryId);
        formData.append("page", pageno);
        formData.append("per_page", 200);
        formData.append("type", themeType);
        Axios.post(configURL.partner_themeListing, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                const filteredThemes = res?.data?.result.filter(item => item?.status !== "offline");
                const allThemes = [{ name: "All Themes", value: "" }, ...filteredThemes];
                setThemes(allThemes)
                if (status !== "initial") {
                    setQuestionThemes(filteredThemes);
                }
                EziLoader.hide();
            } else {
                EziLoader.hide();
                setThemes([]);
            }
        }).catch(err => {
            console.log(err);
            EziLoader.hide();
        })
    };

    /**
     * Fetches sub-themes based on the provided data and page number.
     *
     * @param {string} data - The data used to fetch sub-themes.
     * @param {number} [pageno=1] - The page number to fetch sub-themes from.
     * @return {void}
     */
    const handleSubThemesListing = (data, pageno = 1) => {
        if (!data) {
            return;
        }
        setQuestionData({ ...questionData, theme_id: data });
        setSubQuestionData({ ...subQuestionData, theme_id: "" });
        let formData = new FormData();
        formData.append("theme_id", data);
        formData.append("category_id", category?.id);
        formData.append("type", 'template');
        formData.append("page", pageno);
        formData.append("per_page", 200);
        Axios.post(configURL.partner_subthemeListing, formData).then(res => {
            if (res.data.success === true) {
                const filteredSubThemes = res.data.result.filter(item => item.status !== "offline");
                const allSubThemes = [{ name: "All Sub-Themes", value: "" }, ...filteredSubThemes];
                setSubThemes(allSubThemes);
                if (status !== "initial") {
                    setSubQuestionThemes(filteredSubThemes);
                }
            } else {
                setSubThemes([]);
                toast.warn(res.data.message || 'Something went wrong.')
            }
        }).catch(err => {
            console.log(err);
            toast.warn('Something went wrong.')
        });
    };

    /**
     * Saves a question to the question bank.
     *
     * @return {Promise<void>} A Promise that resolves when the question is saved successfully,
     * or rejects with an error if there was an issue.
     */
    const handleSaveQuestion = () => {
        EziLoader.show();
        let questionJSON = {
            "type": "rating",
            "name": "question1",
            "title": questionData.title,
            "id": uniqueGenerator(),
            "question_type": "TEXTPICKER",
            "addToQuestionBank": true,
            "questionScope": questionData.scope,
            "category": "ac946fdb-be58-42b2-87df-c89ac0f31545", "category_id": questionData.category_id, "theme_id": questionData.theme_id,
            "hasCalculation": true,
            "rateValues":[{"value":"1","text":"Strongly Disagree"},{"value":"2","text":"Disagree"},{"value":"3","text":"Neutral"},{"value":"4","text":"Agree"},{"value":"5","text":"Strongly Agree"}],
        };
        if (questionData.title === "" || questionData.category_id === "" || questionData.theme_id === "" || questionData.scope === "") {
            toast.warn("Please fill all the fields");
            EziLoader.hide();
            return;
        }
        if (selectedValueForSubCompetency) { questionJSON["sub_theme_id"] = subQuestionData.theme_id }
        let formData = new FormData();
        formData.append("question_json", JSON.stringify(questionJSON));
        Axios.post(configURL.addToQuestionBank, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                toast.success(res.data.message);
                setQuestionData({ title: "", category_id: "", theme_id: "", scope: true });
                setShowModal(false);
                getQuestions();
                setCategorySelectValue(null);
                setSelectedValueForCompetency(null);
                setSelectedValueForSubCompetency(null);
                EziLoader.hide();
            } else {
                EziLoader.hide();
                toast.error(res.data.message);
            }
        }).catch(err => {
            console.log(err);
            EziLoader.hide();
        })
        handleCloseModal();
    }

    /**
     * Handles the listing of categories.
     *
     * @param {Object} data - The data object containing the category value.
     * @return {void}
     */
    const handleCategoryListing = (data) => {
        handleThemeListing(data.value);
        setShowMenu({ ...showMenu, competency: true });
        setQuestionData({ ...questionData, category_id: data.value });
        setCategorySelectValue(data);
        setCategorySelectValue(null);
        setSelectedValueForCompetency(null);
        setSelectedValueForSubCompetency(null);
    }

    /**
     * Handles the event when a competency is added to the menu.
     *
     * @param {Object} data - The data object containing the selected competency.
     * @return {void} This function does not return anything.
     */
    const handleAddCompetencyListing = (data) => {
        setShowMenu({ ...showMenu, subCompetency: true });
        handleSubThemesListing(data.value);
        // setCategorySelectValue(data);
        setSelectedValueForCompetency(data);
        setSelectedValueForSubCompetency(null);
    }

    
    /**
     * Submits the theme data to the server and performs the necessary actions based on the response.
     *
     * @param {Object} data - The data object containing the theme information.
     * @return {void}
     */
    const onSubmitTheme = (data) => {
        if (!status.value) {
            setStatus({ value: null, error: true });
            return
        }
        let id = modalType === "competency" ? categorySelectValue.value : selectedValueForCompetency.value;
        let type = modalType === "competency" ? "category_id" : "theme_id";
        data[type] = id
        data['status'] = status?.value?.value;
        let sendDataObj = {}
        Object.keys(data).forEach(el => {
            sendDataObj[el] = data[el]
        })
        const sendData = new FormData();
        let addData = {
            'category': modalType === "competency" ? 'theme' : 'sub-theme',
            'data': sendDataObj,
        }
        sendData.append("type", 'theme');
        sendData.append("inserttheme", JSON.stringify(addData));
        if (modalType === "competency") {
            Axios.post(configURL.partner_addTheme, sendData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    toast.success(res.data.message);
                    setModalType("add-question");
                    handleThemeListing(categorySelectValue.value);
                } else {
                    toast.warn(res.data.message);
                }
            })
        }
        if (modalType === "sub-competency") {
            Axios.post(configURL.partner_addSubTheme, sendData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    toast.success(res.data.message);
                    setModalType("add-question");
                    handleSubThemesListing(selectedValueForCompetency.value);
                } else {
                    toast.warn(res.data.message || "Something went wrong");
                }
            })
        }
    };

    /**
     * Handles the deletion of a question by displaying a confirmation alert and sending a request to the server.
     *
     * @param {Object} questionId - The ID of the question to be deleted.
     * @return {void} This function does not return anything.
     */
    const handleDelete = (questionId) => {
        confirmAlert({
            title: 'Delete Statement',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        EziLoader.show();
                        let formData = new FormData();
                        formData.append("question_bank_ids", JSON.stringify([questionId.question_bank_id]));
                        Axios.post(configURL.delete_question_bank_questions, formData).then(res => {
                            if (res.data.success !== undefined && res.data.success) {
                                getQuestions();
                                EziLoader.hide();
                                toast.success(res.data.message || "Statement Deleted Successfully");
                            } else {
                                setQuestions([]);
                                EziLoader.hide();
                            }
                        }).catch(err => {
                            console.log(err);
                            EziLoader.hide();
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    };

    useEffect(getInitialCatgory, []);

    useEffect(() => {
        if (category?.id) {
            getQuestions();
        }
    }, [category, theme, subTheme]);


    useEffect(() => {
        const results = categoryDs.filter(ele => {
            return ele.category_name.toLowerCase().includes(searchTerm.toLowerCase())
        });
        setSearchResults(results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        const results = themes.filter(ele => {
            return ele.name.toLowerCase().includes(searchThemeTerm.toLowerCase())
        });
        setThemeSearchResults(results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchThemeTerm]);

    useEffect(() => {
        const results = subThemes.filter(ele => {
            return ele.name.toLowerCase().includes(searchSubTheme.toLowerCase())
        });
        setSubThemeSearchResults(results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchSubTheme]);

    let categoryList = (searchTerm !== '') ? searchResults : categoryDs;
    let themeList = (searchThemeTerm !== '') ? themeSearchResults : themes;
    let subThemeList = (searchSubTheme !== '') ? subThemeSearchResults : subThemes;

    return (
        <React.Fragment>
            <section className="Page-QuestionBank" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate("/dashboard")}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate("/questionbank")}>QuestionBank</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="themes-dropdown" >
                        <div className="category-dropdown-wrapper">
                            <button type="button" onClick={dropdown} className={`themes-dropdown-btn ${category.category_name ? "selected" : ""}`}>{(category.category_name) ? category.category_name : languageObj.translate('SelectCategory.1')}</button>
                            <div className={`themes-inner-dropdown ${dropdownFlag ? 'active' : ""}`} ref={ref}>
                                <div className="themes-dropdown-header">
                                    <input type="text" className="themes-dropdown-search" value={searchTerm} onChange={(e) => searchCategory(e)} placeholder={languageObj.translate('Search.1')} />
                                    <label>{languageObj.translate('Category.1')}</label>
                                </div>
                                <ul>
                                    {

                                        categoryList.map(categoryData =>
                                            <li onClick={() => changeCategory(categoryData)} key={categoryData.id}>
                                                {categoryData.category_name}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className={`${theme && theme.name !== "All Themes" ? "category-dropdown-wrapper-with-border" : "theme-dropdown-wrapper"}`}>
                            <button type="button" onClick={dropdownThemes} className={`themes-dropdown-btn question-bank-theme ${theme.name ? "selected" : ""}`}>{(theme.name) ? theme.name : languageObj.translate('SelectTheme.1')}</button>
                            <div className={`themes-inner-dropdown question-bank-theme-inner ${dropdownThemeFlag ? 'active' : ""}`} ref={refTheme}>
                                <div className="themes-dropdown-header">
                                    <input type="text" className="themes-dropdown-search" value={searchThemeTerm} onChange={(e) => searchThemes(e)} placeholder={languageObj.translate('Search.1')} />
                                    <label>{languageObj.translate('Theme.1')}</label>
                                </div>
                                <ul>
                                    {

                                        themeList.map(themeData =>
                                            <li onClick={() => changeTheme(themeData)} key={themeData.id}>
                                                {themeData.name}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>

                        {theme && theme.name !== "All Themes" && <div className="theme-dropdown-wrapper">
                            <button type="button" onClick={dropdownSubThemes} className={`themes-dropdown-btn question-bank-theme ${subTheme.name ? "selected" : ""}`}>{(subTheme.name) ? subTheme.name : "Select Sub-Theme"}</button>
                            <div className={`themes-inner-dropdown question-bank-theme-inner ${dropdownSubThemeFlag ? 'active' : ""}`} ref={refSubTheme}>
                                <div className="themes-dropdown-header">
                                    <input type="text" className="themes-dropdown-search" value={searchSubTheme} onChange={(e) => searchSubThemes(e)} placeholder={languageObj.translate('Search.1')} />
                                    <label>Sub-Theme</label>
                                </div>
                                <ul>
                                    {
                                        subThemeList.map(data =>
                                            <li onClick={() => changeSubTheme(data)} key={data.id}>
                                                {data.name}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>}
                        <div className="column-header-btn">
                            <button type="button" className="btn-ripple add-new " onClick={handleShowModal}>Add New Statement</button>
                        </div>
                    </div>

                    {/* <span className="themes-subtitle">Question Bank</span> */}
                </div>
                <div className="themes-content">

                    <div className="ezi-sweet-tab">
                        <div className="filter-search-wrap">
                            <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                        </div>
                        <RemotePagination
                            data={questions}
                            page={tableMeta.pagination.current_page}
                            sizePerPage={tableMeta.pagination.per_page}
                            totalSize={tableMeta.pagination.total}
                            onTableChange={handleAllEntriesChange}
                        />
                    </div>

                </div>
                {showModal && <Modal show={showModal} size="md" onHide={handleCloseModal} centered animation={true} className="question-bank-modal" >
                    <Modal.Body>
                        {/* modal body for add question  */}
                        {modalType === "add-question" && <section className="Page-QuestionBankModal">
                            <div className="question-wrapper-section">
                                <div className="questions-header">
                                    <span className="question-heading">Add Statement</span>
                                    <div className="question-save-wrap">

                                        <button type="button" onClick={handleCloseModal} className="question-reset ml-3">X</button>
                                    </div>
                                </div>
                                <div className="que-mapping-body">
                                    <div className="question-wrapper">
                                        <div className="ques-display">
                                            <div className="ques-wrap">
                                                <label>Statement</label>
                                                <input type="text" className="theme-field-control" name="name" placeholder="Enter Statement Title" defaultValue={questionData?.title} onChange={(e) => setQuestionData({ ...questionData, title: e.target.value })} />
                                            </div>
                                            <div className="ques-wrap">
                                                <label>Category</label>
                                                <Select
                                                    className="select select-react pl-0"
                                                    options={categoryList.map(option => ({ value: option.id, label: option.category_name }))}
                                                    styles={outlineRemove}
                                                    placeholder="Select Category"
                                                    onChange={(data) => {
                                                        handleCategoryListing(data);
                                                        setCategorySelectValue(data)
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    value={categorySelectValue}
                                                />
                                            </div>
                                        </div>
                                        <div className="ques-display">
                                            {questionData?.title && showMenu.competency && <div className="ques-wrap">
                                                <div className="d-flex justify-content-between">
                                                    <label>Theme</label>
                                                    <button className="add-competency" onClick={() => setModalType("competency")}>+ Add Theme</button>
                                                </div>
                                                <Select
                                                    className="select select-react pl-0"
                                                    options={questionThemes.map(option => ({ value: option.id, label: option.name }))}
                                                    styles={outlineRemove}
                                                    placeholder="Select Theme"
                                                    onChange={(data) => handleAddCompetencyListing(data)}
                                                    menuPortalTarget={document.body}
                                                    value={selectedValueForCompetency}
                                                />
                                            </div>}
                                            {questionData?.title && showMenu.competency && showMenu.subCompetency && <div className="ques-wrap">
                                                <div className="d-flex justify-content-between">
                                                    <label>Sub-Theme</label>
                                                    <button className="add-competency" onClick={() => setModalType("sub-competency")}>+ Add Sub-Theme</button>
                                                </div>
                                                <Select
                                                    className="select select-react pl-0"
                                                    options={subQuestionThemes.map(option => ({ value: option.id, label: option.name }))}
                                                    styles={outlineRemove}
                                                    placeholder="Select Sub-Theme"
                                                    onChange={(data) => {
                                                        setSubQuestionData({ ...subQuestionData, theme_id: data.value });
                                                        setSelectedValueForSubCompetency(data)
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    value={selectedValueForSubCompetency}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="btn-cover">
                                        <button type="button" className="btn-ripple ezi-pink-btn question-save" onClick={handleSaveQuestion}>{languageObj.translate('Save.1')}</button>
                                        <button type="button" className="close-alert-btn" onClick={handleCloseModal}>{languageObj.translate('Cancel.1')}</button>
                                    </div>

                                </div>
                            </div>
                        </section>}
                        {/* modal body for add competency  */}
                        {/* <AddThemeModal> --> here  */}
                        {(modalType === "competency" || modalType === "sub-competency") && <section className="Page-QuestionBankModal theme-modal">
                            <div className="question-wrapper-section">
                                <div className="questions-header">
                                    <span className="question-heading">{modalType === "competency" ? 'Add Theme' : 'Add Sub-Theme'}</span>
                                    <div className="question-save-wrap">
                                        <button type="button" onClick={handleCloseModal} className="question-reset ml-3">X</button>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmitTheme)} className="add-theme-form">
                                    <div className="que-mapping-body">
                                        <div className="question-wrapper">
                                            <div className="ques-display">
                                                <div className="ques-wrap">
                                                    <label>Category</label>
                                                    <Select
                                                        className="select select-react pl-0"
                                                        options={categoryList && categoryList.map(option => ({ value: option.id, label: option.category_name }))}
                                                        styles={outlineRemove}
                                                        placeholder="Select Category"
                                                        onChange={(data) => {
                                                            setQuestionData({ ...questionData, category_id: data.value })
                                                            handleThemeListing(data.value);
                                                            setCategorySelectValue(data);
                                                            // setThemeSelectValue(null);
                                                        }}
                                                        value={categorySelectValue}
                                                    />
                                                </div>
                                            </div>
                                            {categorySelectValue && modalType === "sub-competency" && <div className="ques-display">
                                                <div className="ques-wrap">
                                                    <label>Theme</label>
                                                    <Select
                                                        className="select select-react pl-0"
                                                        options={questionThemes.map(option => ({ value: option.id, label: option.name }))}
                                                        styles={outlineRemove}
                                                        placeholder="Select Theme"
                                                        onChange={(data) => {
                                                            handleSubThemesListing(data.value);
                                                            setSelectedValueForCompetency(data);
                                                        }}
                                                        menuPortalTarget={document.body}
                                                        value={selectedValueForCompetency}
                                                    />
                                                </div>
                                            </div>}
                                            <div className="ques-display">
                                                <div className="ques-wrap">
                                                    <label>{modalType === "competency" ? 'Theme' : 'Sub-Theme'}</label>
                                                    <input type="text" className="theme-field-control" name="name" ref={register({ required: true })} placeholder={`Enter ${modalType === "competency" ? 'Theme' : 'Sub-Theme'} Name`} />
                                                    {errors.name && <span className="theme-error_cu">{`* ${modalType === "competency" ? 'Theme' : 'Sub-Theme'} name is required.`}</span>}
                                                </div>
                                                <div className="ques-wrap">
                                                    <label>Status</label>
                                                    <Select
                                                        className="select select-react pl-0"
                                                        options={[{ value: true, label: 'Active' }, { value: false, label: 'Offline' }]}
                                                        styles={outlineRemove}
                                                        placeholder="Select Status"
                                                        ref={register({ required: true })}
                                                        menuPortalTarget={document.body}
                                                        onChange={(data) => {
                                                            setStatus({ value: data, error: false })
                                                        }}
                                                        name="status"
                                                    />
                                                    {status.error && <span className="theme-error_cu">{`* ${modalType === "competency" ? 'Theme' : 'Sub-Theme'} status is required.`}</span>}
                                                </div>
                                                <div className="ques-wrap">
                                                    <label>{`${modalType === "competency" ? 'Theme' : 'Sub-Theme'} Description`}</label>
                                                    <textarea rows="4" className="theme-field-control" name="description" ref={register} placeholder={`Enter ${modalType === "competency" ? 'Theme' : 'Sub-Theme'} Description`}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-cover">
                                            <button type="button" className="close-theme-btn" onClick={() => setModalType('add-question')}>Back</button>
                                            <input type="submit" value="Save" className="btn-ripple ezi-pink-btn add-theme-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>}

                        {/* modal body for add sub-competency */}
                        {modalType === "add-sub-competency" && <section className="Page-QuestionBankModal">
                            <div className="question-wrapper-section">
                                <div className="questions-header">
                                    <span className="question-heading">Add Sub-Theme</span>
                                    <div className="question-save-wrap">

                                        <button type="button" onClick={handleCloseModal} className="question-reset ml-3">X</button>
                                    </div>
                                </div>
                            </div>
                        </section>}


                    </Modal.Body>
                </Modal>}
            </section>
        </React.Fragment>
    )
}

export default QuestionBank;