import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import useForm from 'react-hook-form';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { toast } from 'react-toastify';
import Select from 'react-select';


function AddThemeModal({ updatethemelisting, ...props }) {
    const { register, handleSubmit, errors } = useForm();
    const [categorySelectValue, setCategorySelectValue] = useState(null)
    const [themeSelectValue, setThemeSelectValue] = useState(null)

    const onSubmit = data => {
        data[props.commonidname] = props.commonid;
        let sendDataObj = {}
        Object.keys(data).forEach(el => {
            sendDataObj[el] = data[el]
        })
        const sendData = new FormData();
        let addData = {
            'category': props.name,
            'data': sendDataObj,
        }
        sendData.append("type", props.tab);
        sendData.append("inserttheme", JSON.stringify(addData));
        if (props.name === "theme") {
            Axios.post(configURL.partner_addTheme, sendData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    toast.success(res.data.message);
                    props.onHide();
                    updatethemelisting();
                } else {
                    toast.warn(res.data.message);
                }
            })
        }
        if (props.name === "sub-theme") {
            Axios.post(configURL.partner_addSubTheme, sendData).then(res => {
                if (res.data.success !== undefined && res.data.success) {
                    toast.success(res.data.message);
                    props.onHide();
                    updatethemelisting();
                } else {
                    toast.warn(res.data.message);
                }
            })
        }

    };

    useEffect(() => {
        setCategorySelectValue(props?.categorySelectValue)
        //eslint-disable-next-line
    }, [props?.categorySelectValue])
    
    useEffect(() => {
        setThemeSelectValue(props?.themeSelectValue)
        //eslint-disable-next-line
    }, [props?.themeSelectValue])

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered className="theme-modal-wrapper" >
            <Modal.Header className="ezi-modal-header">
                <Modal.Title id="contained-modal-title-vcenter" className="theme-modal-title ezi-modal-header-title" >
                    <span className="theme-modal-title-text">Add New {props.fieldname} </span>
                    <span className="ezi-modal-close" onClick={props.onHide}></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} className="add-theme-form">
                    <div className="theme-field-wrapper">
                        {
                            (props.toggleTheme || props.subToggleTheme) &&
                            <div className="theme-field">
                                <label>Category</label>
                                <Select
                                    className="theme-field-control"
                                    options={props?.categoryList && props?.categoryList.map(option => ({ value: option.id, label: option.category_name }))}
                                    styles={props.outlineRemove}
                                    placeholder="Select Category"
                                    onChange={(data) => {
                                        props.setQuestionData({ ...props.questionData, category_id: data.value })
                                        props.handleThemeListing(data.value);
                                        setCategorySelectValue(data);
                                        setThemeSelectValue(null);
                                    }}
                                    value={(categorySelectValue)}
                                />
                            </div>
                        }
                        {props.subToggleTheme &&
                            <div className="theme-field">
                                <label>Competency</label>
                                <Select
                                    className="theme-field-control"
                                    options={props.questionThemes.map(option => ({ value: option.id, label: option.name }))}
                                    styles={props.outlineRemove}
                                    placeholder="Select Competency"
                                    onChange={(data) => {
                                        props.handleSubThemesListing(data.value);
                                        setThemeSelectValue(data);
                                    }}
                                    menuPortalTarget={document.body}
                                    value={themeSelectValue}
                                />
                            </div>
                        }

                        <div className="theme-field-50">
                            <div className="theme-field">
                                <label>{props.fieldname}</label>
                                <input type="text" className="theme-field-control" name="name" ref={register({ required: true })} placeholder="Enter Theme Name" />
                                {errors.name && <span className="theme-error_cu">* {props.fieldname} is required.</span>}
                            </div>
                            <div className="theme-field">
                                <label>Status</label>
                                <select className="theme-field-control" name="status" ref={register({ required: true })} placeholder="Enter Status">
                                    <option value="true">Active</option>
                                    <option value="false">Offline</option>
                                </select>
                            </div>
                        </div>
                        <div className="theme-field">
                            <label>Description</label>
                            <textarea rows="4" cols="50" className="theme-field-control" name="description" ref={register} placeholder="Enter Description"></textarea>
                        </div>
                    </div>
                    <div className="theme-modal-footer">
                        <button type="button" className="close-theme-btn" onClick={props.onHide}>Close</button>
                        <input type="submit" value="Save" className="btn-ripple ezi-pink-btn add-theme-btn" />
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
}

export default AddThemeModal;