import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import useSidebarHooks from './hooks/SidebarNavbarHook';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './assets/scss/variable.scss';
import './App.scss';
import './assets/font/NunitoSans/NunitoSans.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';
import AppContext from './store/AppContext';
import LANGUAGES from "constants/languages";
import InnerRoutes from 'router/InnerRoutes';
import EziLoader from 'components/EziLoader';
import { connect } from "react-redux";

const Login = React.lazy(() => import('pages/Login/Login'));
const ForgotPassword = React.lazy(() => import('pages/ForgetPassword/ForgotPassword'));

function App({ appState }) {
	/**
	 * Initial App state for login, ACL Matrix
	 */
	const [translate, setTranslate] = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const sidebarData = useSidebarHooks();

	/**
	 * Global Loader
	 */
	const globalLoader = () => {
		return {
			show: () => {
				setIsLoading(true)
			},
			hide: () => {
				setIsLoading(false)
			}
		}
	}

	/**
	 * Initial Language Data.
	 */
	const languageObj = {
		curLang: localStorage.getItem("i18nextLng"),
		languages: LANGUAGES,
		changeLang: (langCode = "En") => setTranslate.changeLanguage(langCode),
		translate: translate
	}

	return (
		<AppContext.Provider value={{ appState, languageObj, EziLoader: globalLoader() }}>
			<Router>
				<Routes>
					<React.Fragment>
						<Route exact path="/" element={<Login/>} />
						<Route exact path="/reset-password" element={ForgotPassword} />
						<Route path="*" exact element={<React.Fragment>
							<div className="main-wrapper">
								<Sidebar sidebarPara={sidebarData} />
								<Navbar navbarPara={sidebarData} />
								<div className="inner-wrapper">
									<InnerRoutes />
									<div className="footer-bottom">
										<p>&copy; {new Date().getFullYear()} QaizenX &reg;. All Rights Reserved.</p>
									</div>
								</div>
								<div className={`overlay ${sidebarData.sidebar ? "active" : ""}`} onClick={sidebarData.overlaySidebar} ></div>
							</div>
						</React.Fragment>} />
				</React.Fragment>
				</Routes>
			</Router>
			<ToastContainer transition={Zoom} position={toast.POSITION.TOP_RIGHT} closeButton={false} hideProgressBar={true} />
			{isLoading && <EziLoader />}
		</AppContext.Provider>
	);
}

const mapStateToProps = state => {
	return {
		appState: state.app.appState,
		language: state.app.language,
	}
}

export default connect(mapStateToProps)(App);