export const CUSTOM_LANGUAGES = {
    hi: {
        label: "हिन्दी",
        localeStrings: {
            pagePrevText: "पिछला",
            pageNextText: "अगला",
            completeText: "पूर्ण",
            startSurveyText: "शुरू करें",
            previewText: "पूर्वावलोकन",
            editText: "संपादित करें",
            clearCaption: "मिटाएं"
        }
    },
    mh: {
        label: "मराठी",
        localeStrings: {
            pagePrevText: "मागचे",
            pageNextText: "पुढे",
            completeText: "पूर्ण",
            startSurveyText: "सुरु करा",
            previewText: "पूर्वावलोकन",
            editText: "सुधारणे",
            clearCaption: "पुसून टाका"
        }
    },
    gu: {
        label: "ગુજરાતી",
        localeStrings: {
            pagePrevText: "પૂર્વાવલોકન",
            pageNextText: "આગળ",
            completeText: "પૂર્ણ",
            startSurveyText: "શરૂઆત",
            previewText: "પૂર્વાવલોકન",
            editText: "સંપાદિત કરો",
            clearCaption: "ભુસવું"
        }
    },
    ta: {
        label: "தமிழ்",
        localeStrings: {
            pagePrevText: "முந்தையது",
            pageNextText: "அடுத்தது",
            completeText: "முழுமை",
            startSurveyText: "தொடங்கு",
            previewText: "முன்னோட்ட",
            editText: "தொகு",
            clearCaption: "அழி"
        }
    },
    te: {
        label: "తెలుగు",
        localeStrings: {
            pagePrevText: "ఇంతకు ముంద",
            pageNextText: "తరువాత",
            completeText: "పూర్తయింది",
            startSurveyText: "ప్రారంభించండి",
            previewText: "పరిదృశ్యం",
            editText: "సవరించండి",
            clearCaption: "క్లియర్"
        }
    },
    pa: {
        label: "ਪੰਜਾਬੀ",
        localeStrings: {
            pagePrevText: "ਪਿਛਲਾ",
            pageNextText: "ਅਗਲਾ",
            completeText: "ਮੁਕੰਮਲ",
            startSurveyText: "ਸ਼ੁਰੂ",
            previewText: "ਝਲਕ",
            editText: "ਸੰਪਾਦਿਤ ਕਰੋ",
            clearCaption: "ਮਿਟਾਓ"
        }
    },
    as: {
        label: "অসমীয়া",
        localeStrings: {
            pagePrevText: "পূববৰ্তী",
            pageNextText: "পৰবৰ্তী",
            completeText: "পূর্ণ",
            startSurveyText: "আৰম্ভ",
            previewText: "পূৰ্বলোকন",
            editText: "সম্পাদনা",
            clearCaption: "পৰিষ্কাৰ"
        }
    },
    or: {
        label: "ଓଡିଆ",
        localeStrings: {
            pagePrevText: "ପୂର୍ବବର୍ତ୍",
            pageNextText: "ପରବର୍ତ୍ତି",
            completeText: "ସଂପୂର୍ଣ୍ଣ",
            startSurveyText: "ଆରମ୍ଭ",
            previewText: "ପୂର୍ବାବଲୋକନ",
            editText: "ସମ୍ପାଦନ",
            clearCaption: "ଲିଭାନ୍ତୁ"
        }
    },
    ml: {
        label: "മലയാളം",
        localeStrings: {
            pagePrevText: "മുൻപത്തേത്",
            pageNextText: "അടുത്തത്",
            completeText: "പൂർത്തിയായി",
            startSurveyText: "ആരംഭിക്കുക",
            previewText: "പിവു",
            editText: "എഡിറ്റുചെയ്യുക",
            clearCaption: "മായ്ക്കുക"
        }
    },
    kn: {
        label: "ಕನ್ನಡ",
        localeStrings: {
            pagePrevText: "ಹಿಂದಿನ",
            pageNextText: "ಸಮೀಪ",
            completeText: "ಪೂರ್ಣಗೊಂಡಿದೆ",
            startSurveyText: "ಪ್ರಾರಂಭ",
            previewText: "ಮುನ್ನೋಟ",
            editText: "ತಿದ್ದು",
            clearCaption: "ಅಳಿಸು"
        }
    },
    bn: {
        label: "বাংলা",
        localeStrings: {
            pagePrevText: "পূর্ববর্তী",
            pageNextText: "পরবর্তী",
            completeText: "সম্পূর্ণ",
            startSurveyText: "শুরু করুন",
            previewText: "পূর্বরূপ",
            editText: "সম্পাদনা করুন",
            clearCaption: "পরিষ্কার "
        }
    }
}

export const QUESTION_PROPERTY_VISIBILITY = {
    nps_rating: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParent', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'isIdentifier', 'hasCalculation', 'addToQuestionBank', 'questionScope', 'category'],
    rating_csat: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParent', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'isIdentifier', 'hasCalculation', 'addToQuestionBank', 'questionScope', 'category'],
    smily: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParent', 'hasComment', 'commentText', 'showClearButton', 'isOverriding', 'isIdentifier', 'hasCalculation', 'addToQuestionBank', 'questionScope', 'category'],
    yesno: ["title", "description", "isRequired", "visible", 'visibleIf', 'readOnly', 'hasParent', 'valueName', 'defaultValue', 'showClearButton', 'addToQuestionBank', 'questionScope', 'category'],
    html: ['addToQuestionBank', 'questionScope', 'category']
}