import React from 'react';
import { Routes, Route } from "react-router-dom";
import RootRouter from "./router";
import PrivateRoute from './PrivateRoute';
import { connect } from "react-redux";

/**
 * Lazy Load page
 */
const PageNotFound = React.lazy(() => import('components/PageNotFound'));

const InnerRoutes = ({ appState }) => (

    <Routes>
        {
            RootRouter.map((route, index) => {
                const { path, component: Component,  } = route;
                if (appState.is_main_user === route.main_user) {
                    return (
                            <Route
                                key={index}
                                path={path}
                                exact
                                element={<PrivateRoute element={<Component />} />}
                            />
                        );
                }
                if (route.skip_access === true) {
                    return (
                            <Route
                                key={index}
                                path={path}
                                exact
                                element={<PrivateRoute element={<Component />} />}
                            />
                        );
                }
                return null
            })
        }
        <Route path="*" element={<PrivateRoute element={<PageNotFound />} />} />
    </Routes>
)
const mapStateToProps = state => {
    return {
        appState: state.app.appState,
    }
}
export default connect(mapStateToProps)(InnerRoutes);