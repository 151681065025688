import React, { useState, useEffect } from "react";
import './BenchmarkListing.scss';
import { Breadcrumb, Tab } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import Axios from "utility/Axios";
import configURL from 'config/config';
import EziLoader from "components/EziLoader";
import { confirmAlert } from 'react-confirm-alert';
import BenchmarkForm from "./BenchmarkForm";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import { FaUpload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

    const BenchmarkListing = (props) => {
    const [bencharkModel, setBenchmarkModal] = useState(false);
    const [editModelData, setEditModelData] = useState({});
    const [benchmarks, setBenchmarks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    let urlParams = useParams();
    const navigate = useNavigate();

    /**
     * Redirects to a sub-benchmark based on the given row.
     *
     * @param {Object} row - The row object containing benchmark information.
     */
    const redirectToSubBenchmark = (row) => {
        const rowIndex = benchmarks.findIndex((item) => item.id === row.id);
        let benchmark = benchmarks[rowIndex];
        navigate(`/currentbenchmarks/${urlParams?.account_id}`, {
            state: {benchmarkId: benchmark.id,
            BMname: benchmark.name,}
        });
    }

    /**
     * Redirects to a benchmark bulk upload based on the given survey.
     *
     * @param {Object} row - The row object containing benchmark information.
     */
    const bulkUpload = (row) => {
        let benchmark_id = row.id;
        navigate(`/benchmark-bulk-uplaod/${benchmark_id}`,
        {
            state:{benchmarkId: benchmark_id,
            BMname: row.name,}
        });
    }

    /**
     * Toggles the visibility of the edit theme modal.
     *
     * @return {void} - No return value
     */
    const toggleEditThemeModal = () => {
        setEditModalVisible(!editModalVisible);
    }
    
    /**
     * Generates a function comment for the given function body.
     *
     * @param {type} cell - the value of the 'cell' parameter
     * @param {type} row - the value of the 'row' parameter
     * @return {type} the formatted JSX element
     */
    const themeNameFormatter = (cell, row) => {
        return (
            <span className="benchmark-name_c">{cell}</span>
        );
    }

    /**
     * Generates the action element for a cell in a table row.
     *
     * @param {Object} cell - the cell object
     * @param {Object} row - the row object
     * @return {JSX.Element} - the action element
     */
    const actionFormatter = (cell, row) => {
        let row_Id = row.id;
        return (
            <div className="benchmark-editDelete-wrap">
                <OverlayTrigger placement="top"
                    overlay={<Tooltip>View statement</Tooltip>}>
                    <button type="button" className={`benchmark-view`} onClick={() => {
                        redirectToSubBenchmark(row)
                    }}></button></OverlayTrigger>
                <OverlayTrigger placement="top"
                    overlay={<Tooltip>Edit Benchmark</Tooltip>}>
                    <button type="button" className={`benchmark-edit`} onClick={() => {
                        setEditRowData(row)
                    }}></button></OverlayTrigger>
                <OverlayTrigger placement="top"
                    overlay={<Tooltip>Delete Benchmark</Tooltip>}>
                <button type="button" className={`benchmark-delete`} onClick={() => {
                    deleteEntry(row_Id)
                }}></button>
            </OverlayTrigger>
                <OverlayTrigger placement="top"
                    overlay={<Tooltip>Benchmark Bulk Upload</Tooltip>}>
                <button type="button" className={`benchmark-upload`} onClick={() => {
                    bulkUpload(row)
                }}><FaUpload className="bulk-benchamrk-icon"/></button>
            </OverlayTrigger>
            </div >
        );
    }

const columns = [
    {
        dataField: "name", text: "Benchmark Names",
        formatter: themeNameFormatter,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                redirectToSubBenchmark(row);
            }
        },
    },
    {
        dataField: 'action', text: 'Action',
        formatter: actionFormatter,
    },
];

/**
 * Renders a remote pagination component.
 *
 * @return {ReactElement} The rendered remote pagination component.
 */
const RemotePagination = () => (
    <BootstrapTable
        remote
        keyField="id"
        data={benchmarks}
        columns={columns}
        noDataIndication="Table is Empty"
        rowClasses="benchmark-datatable-row"
    />
);

/**
 * Sets the edit row data and toggles the edit theme modal.
 *
 * @param {any} rowData - The data to be set as the edit row data.
 * @return {undefined} This function does not return a value.
 */
const setEditRowData = (rowData) => {
    toggleEditThemeModal();
    setEditModelData(rowData);
}

/**
 * Deletes an entry based on the provided row ID.
 *
 * @param {string} row_Id - The ID of the row to be deleted.
 * @return {undefined} This function does not return a value.
 */
const deleteEntry = (row_Id) => {
    confirmAlert({
        title: 'Delete Benchmark',
        message: `Are you sure? Once you delete the Benchmark, it will be permanently deleted.`,
        buttons: [
            {
                label: 'Okay',
                onClick: () => {
                    if (row_Id) {
                        let formData = new FormData();
                        formData.append("benchmark_id", row_Id)
                        Axios.post(configURL.delete_custom_benchmark, formData)
                            .then(response => {
                                if (response !== undefined && response.status === 200) {
                                    getInitialCatgory(benchmarks);
                                }
                            })
                            .catch(error => {
                                console.error('Error deleting benchmark:', error);
                            });
                    }
                }
            },
            { label: 'Cancel' }
        ]
    });
};

/**
 * Fetches the initial category data from the server.
 *
 * @param {type} paramName - description of parameter
 * @return {type} description of return value
 */
const getInitialCatgory = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("language_id", "En");
    formData.append("account_id", urlParams?.account_id);
    Axios.post(configURL.get_benchmark_list, formData).then(res => {
        setLoading(false);
        if (res.data.success !== undefined && res.data.success) {
            setBenchmarks(res.data.result);
        } else {
            setBenchmarks([]);
        }
    }).catch(err => {
        console.log(err);
        setLoading(false);
    })
};
useEffect(getInitialCatgory, []);
return (
    <React.Fragment>
        <section className="Page-benchmark main-bench-wrap" >
            <div className="breadcrumb_ezi head-bor">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate(-1)}>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(-1)}>Companies</Breadcrumb.Item>
                    <Breadcrumb.Item >Benchmarks</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="breadcrumb_ezi page-head">
                <div className="column-header benchmark-header">
                    <div><h1 className="page-heading"> Benchmark </h1></div>
                    <div className="dis-set"><button type="button" className={`btn-ripple ezi-pink-btn add-benchmark-btn `} onClick={() => {
                        setBenchmarkModal(true)
                    }}>Create New Benchmark<span className="add_ic"></span></button></div>
                </div>
            </div>
            <BenchmarkForm show={bencharkModel} onHide={() => setBenchmarkModal(false)} name="benchmark" updateBenchmarklisting={() => getInitialCatgory()} />
            <BenchmarkForm show={editModalVisible} onHide={() => toggleEditThemeModal()} toggleeditmodal={() => toggleEditThemeModal()} name="benchmark" editdata={editModelData} updateBenchmarklisting={() => getInitialCatgory()} />
            <div className="benchmark-content">
                <div className="ezi-sweet-tab">
                    <Tab.Container  >
                        <Tab.Content>
                            <RemotePagination
                                data={benchmarks}
                            />
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </section>
        {loading && <EziLoader />}
    </React.Fragment>
)
}
export default BenchmarkListing;
