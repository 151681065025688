import React, { useState, useEffect, useContext, useRef } from 'react';
import './Companies.scss';
import { Breadcrumb, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { toast } from 'react-toastify';
import Axios from "utility/Axios";
import configURL from 'config/config';
import SweetSearch from "components/SweetSearch";
import { confirmAlert } from 'react-confirm-alert';
import AppContext from 'store/AppContext';
import { AiOutlineMore} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Companies = () => {
    const { EziLoader, languageObj = {} } = useContext(AppContext)
    const [accounts, setAccounts] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [tableMeta, setTableMeta] = useState({
        pagination: {},
        columns: [{}],
        uniqueKey: "id"
    });
    const per_page = 10
    let searchTimer = null
    let inputSearch = useRef(null)
    const navigate = useNavigate();

    /**
     * Redirect To template Allocation
     * @param {Object} rowData 
     */
    const allocateTemplate = (rowData) => {
        navigate(`/company-templates/${rowData.account_id}`, {
           state:{ name: rowData.name,
            category_id: "",
            category_name: "All Categories"}
        });
    }
    /**
     * Redirect To Assign Anonymity
     * @param {Object} rowData 
     */
    const assignAdvancedReport = (data) => {
        navigate(`/assign-advanced-report/${data?.account_id}`, {
           state:{ anonymityCompany: data?.anonamity_value}
        });
    }

    const handleManagerPractices = (data) => {
        navigate(`/manager-practices`, {
           state:{ companyData: data }
        });
    }

    const syncCommunication = (data) => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("account_id", data?.account_id);
        Axios.post(configURL.sync_communication, formData).then(response => {
            if (response.data.success && response.data.success === true) {
                toast.success(response.data.message.toString() || "Synced Successfully")
            } else {
                toast.warn(response.data.message.toString() || "Something went wrong")
            }
        }).finally(() => {
            EziLoader.hide();
        })
    }

    const getBenchmarkList = (data) => {
        navigate(`/benchmark-listing/${data?.account_id}`, {
            state:{anonymityCompany: data?.anonamity_value}
        });
    }

    /**
     * Delete Company Data
     * @param {Object} rowData 
     */
    const deleteCompany = (rowData, page) => {
        confirmAlert({
            title: 'Delete Company',
            message: 'Are you sure you want to delete ?',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        let formData = new FormData();
                        formData.append("account_id", rowData.account_id);
                        Axios.post(configURL.compnay_delete, formData).then(response => {
                            if (response.data.success && response.data.success === true) {
                                toast.success(response.data.message.toString() || "Company Deleted")
                                getCompanies(page)
                            } else {
                                toast.warn(response.data.message.toString() || "Something went wrong")
                            }
                        })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });

    }

    /**
     * Get Dynamic Table
     * @param {*} param
     */
    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => {
        return <BootstrapTable
            remote
            keyField={tableMeta.uniqueKey || "id"}
            noDataIndication="No Data Available"
            data={data}
            columns={tableMeta.columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
        />
    }

    /**
     * Handele Table Change on events like pagination and cell edit
     * @param {*} type 
     * @param {*} param1 
     */
    const handleTableChange = (type, { page }) => {
        if (type === 'pagination') {
            EziLoader.show()
            getCompanies(page);
        }
    }

    /**
     * Search Table Handler
     */
    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getCompanies();
        }, 1000);
    }

    const actionHeader = (cell, row) => {
        return (
            <span className="datatable-action-text">Actions</span>
        );
    }

    /**
     * Handle Company Login
     * @param {Object} row 
     */
    const handleCompanyLogin = (row) => {
        let formData = new FormData();
        formData.append("account_id", row.account_id);
        Axios.post(configURL.get_company_token, formData).then(response => {
            if (response.data.success && response.data.success === true) {
                let webAddress = `https://${response.data.webaddress}/company-login/${response.data.token}`
                window.open(webAddress)
            } else {
                toast.warn(response.data.message.toString() || "Something went wrong")
            }
        })
    }

    const actionFormatter = (cell, row, page) => {
        return (
            <>            
            {/* <div className="template-action-wrap"> */}
                {/* <OverlayTrigger overlay={<Tooltip>Allocate Template</Tooltip>}>
                    <button type="button" className="allocate_template" onClick={() => { allocateTemplate(row); }}> </button>
                </OverlayTrigger> */}
                {/* <OverlayTrigger overlay={<Tooltip>Edit Company Details</Tooltip>}>
                    <button type="button" className="edit_template" onClick={() => {
                        navigate(`/update-company/${row.account_id}`)
                    }} > </button>
                </OverlayTrigger> */}
                {/* <OverlayTrigger overlay={<Tooltip>Delete Company</Tooltip>}>
                    <button type="button" className="delete_template" onClick={() => deleteCompany(row, page)}> </button>
                </OverlayTrigger> */}
                {/* <OverlayTrigger overlay={<Tooltip>Login To Company</Tooltip>}>
                    <button type="button" className="company_login" onClick={() => handleCompanyLogin(row)}> </button>
                </OverlayTrigger> */}
            {/* </div> */}
            
            <div className="template-action-wrap">
            <Dropdown className='action-drop'>
                <Dropdown.Toggle style={{border:'0',background: 'transparent',padding: 0,fontSize: '1.2rem',display: 'inline-flex',alignItems: 'center',fontFamily: "NunitoSansSemiBold"}}> <AiOutlineMore className="dots" style={{fontFamily: 'NunitoSansSemiBold',fontSize: '1rem',color: '#000'}}/> </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <div className="action-menu" >
                            {/* <OverlayTrigger overlay={<Tooltip>Allocate Template</Tooltip>}> */}
                            <button type="button" className="allocate_template" onClick={() => { allocateTemplate(row); }}>Allocate Template</button>
                            {/* </OverlayTrigger> */}
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <div className="action-menu" >
                            {/* <OverlayTrigger overlay={<Tooltip>Assign Anonymity</Tooltip>}> */}
                            <button type="button" className="assign_anonymity" onClick={() => { assignAdvancedReport(row) }}>Assign Advanced Report</button>
                            {/* </OverlayTrigger> */}
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        {/* <OverlayTrigger overlay={<Tooltip>Edit Company Details</Tooltip>}> */}
                            <button type="button" className="edit_template" onClick={() => {
                            navigate(`/update-company/${row.account_id}`)
                            }} >Edit Company Details </button>
                        {/* </OverlayTrigger> */}
                    </Dropdown.Item>
                    <Dropdown.Item>
                        {/* <OverlayTrigger overlay={<Tooltip>Login To Company</Tooltip>}> */}
                        <button type="button" className="company_login" onClick={() => handleCompanyLogin(row)}>Login To Company </button>
                        {/* </OverlayTrigger> */}
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <div className="action-menu" >
                            {/* <OverlayTrigger overlay={<Tooltip>Assign Anonymity</Tooltip>}> */}
                            <button type="button" className="allocate_benchmark" onClick={() => { getBenchmarkList(row) }}>Benchmark</button>
                            {/* </OverlayTrigger> */}
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <div className="action-menu" >
                            {/* <OverlayTrigger overlay={<Tooltip>Assign Anonymity</Tooltip>}> */}
                            <button type="button" className="sync_communication" title='Sync Partner Communication Settings To Company' onClick={() => { syncCommunication(row) }}>Sync Communication</button>
                            {/* </OverlayTrigger> */}
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <div className="action-menu" >
                            {/* <OverlayTrigger overlay={<Tooltip>Assign Anonymity</Tooltip>}> */}
                            <button type="button" className="manager_practices" title='Manager Practices' onClick={() => { handleManagerPractices(row) }}>Manager Practices</button>
                            {/* </OverlayTrigger> */}
                        </div>
                    </Dropdown.Item>
                     <Dropdown.Item>
                        {/* <OverlayTrigger overlay={<Tooltip>Delete Company</Tooltip>}> */}
                        <button type="button" className="delete_template" onClick={() => deleteCompany(row, page)}>Delete Company </button>
                        {/* </OverlayTrigger> */}
                     </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
        </>

        );
    }

    /**
     * Get Compnies Data
     */
    const getCompanies = (page = 1) => {
        let formData = new FormData()
        let search = inputSearch.current.value;
        formData.append("page", page);
        formData.append("search", search);
        formData.append("language", languageObj.curLang);
        formData.append("per_page", per_page);

        Axios.post(configURL.company_listing, formData).then(res => {
            EziLoader.hide()
            setSearchLoading(false);
            if (res.data.success !== undefined && res.data.success) {
                setAccounts(res.data.result);
                let columnData = res.data.columns
                columnData.push({ dataField: 'actions', text: '', headerFormatter: actionHeader, formatter: (cell, row) => actionFormatter(cell, row, page) })
                setTableMeta({
                    pagination: res.data.pagination,
                    columns: columnData,
                    uniqueKey: res.data.unique_key || "id"
                })
            } else {
                toast.warn(res.data.message || "Something went wrong")
                setAccounts([]);
            }
        }).catch(err => {
            EziLoader.hide()
            console.log(err.toString())
            setSearchLoading(false);
            toast.error("Something went wrong")
        })
    }

    useEffect(() => {
        EziLoader.show()
        getCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <section className="Page-Companies" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate("/dashboard")}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate("/companies")}>Companies</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">Companies</h1>
                        <div className="column-header-btn">
                            <button type="button" className="btn-ripple add-new " onClick={() => navigate('/add-company')}>Add New Companies</button>
                        </div>
                    </div>
                </div>
                <div className="filter-search-wrap">
                    <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                </div>
                <div className="users-table-content">
                    <RemotePagination
                        data={accounts}
                        page={tableMeta.pagination.current_page}
                        sizePerPage={per_page}
                        totalSize={tableMeta.pagination.total}
                        onTableChange={handleTableChange}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Companies;