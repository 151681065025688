import React, { useState, useEffect, useRef, useContext } from "react";
import { Breadcrumb } from 'react-bootstrap';
import Axios from "utility/Axios";
import configURL from 'config/config';
import { getFirstWord } from 'utility/helper'
import './Templates.scss';
import { toast } from "react-toastify";
import CategoryFilter from "components/SearchFilter";
import SweetSearch from "components/SweetSearch";
import Pagination from "react-js-pagination";
import AppContext from 'store/AppContext';
import { useNavigate, useLocation, useParams } from "react-router-dom";

const AllocateTemplate = (props) => {
    const { EziLoader } = useContext(AppContext)
    const location = useLocation();
    const [defaultCategory, setDefaultCategory] = useState({ ...location.state, description: "" });
    const [categories, setCategories] = useState([]);
    const [categoryTemplates, setCategoryTemplates] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filterType] = useState("all");
    const perPage = 10;
    const [searchLoading, setSearchLoading] = useState(false);
    const inputSearch = useRef(null);
    var searchTimer = null;
    const urlParams = useParams()
    const companyId = urlParams.account_id;
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const navigate = useNavigate();

    /**
     * Get Searched category.
     * 
     * @param {string} search Search string.
     */
    const getCategoriesListing = (search = "") => {
        let formData = new FormData();
        formData.append("search", search);
        formData.append("type", "template");
        Axios.post(configURL.partner_template_category, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                let result = response.data.results;
                const categoryData = [];
                result.forEach(item => {
                    categoryData.push({ id: item.id, name: item.category_name, description: item.description });
                });
                setCategories(categoryData);
            } else {
                toast.warn(response.data.message);
            }
        }).catch(err => {
            console.log(err.toString())
        })
    }

    /**
     * Get Category templates.
     * 
     * @param {object} obj category object
     */
    const changeTemplatesCategory = (obj = {}) => {
        setSelectedTemplates([])
        setDefaultCategory({
            category_id: obj.id || null,
            category_name: obj.name || null,
            description: obj.description || ""
        })
        navigate(`/company-templates/${companyId}`, {
            state:{category_id: obj.id,
            category_name: obj.name}
        }, {replace: true})
    }

    /**
     * Get Perticular template types.
     * 
     * @param {string} type template types
     * @param {number} page Page number
     */
    const getCategoryTemplates = (page = 1, type = "all") => {
        let searchVal = inputSearch.current.value;
        let formData = new FormData();
        formData.append("category_id", defaultCategory.category_id);
        formData.append("account_id", companyId);
        formData.append("per_page", perPage);
        formData.append("page", page);
        formData.append("type", type);
        formData.append("search", searchVal);
        Axios.post(configURL.partner_company_templates, formData).then(response => {
            if (response.data.success !== undefined && response.data.success === true) {
                setCategoryTemplates(response.data.results);
                setPagination(response.data.pagination);
            } else {
                toast.warn(response.data.message);
            }
            EziLoader.hide()
            setSearchLoading(false)
        }).catch(err => {
            EziLoader.hide()
            console.log(err.toString())
        })
    }

    /**
     * Filter Data based on search.
     * 
     * @param {string} type Input value
     */
    const handleFilterSearch = ({ target }) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            getCategoryTemplates(1, filterType);
        }, 800);
    }

    /**
     * Handle Pagination
     * 
     * @param {string} type Filter Type
     */
    const handlePagination = (page = 1) => {
        EziLoader.show()
        getCategoryTemplates(page, filterType);
    }

    const allocateTemplateToCompanies = () => {
        if (selectedTemplates.length > 0) {
            EziLoader.show()
            let formData = new FormData();
            formData.append("account_id", companyId);
            let addData = {
                'data': selectedTemplates
            }
            formData.append("insertdata", JSON.stringify(addData));
            Axios.post(configURL.partner_allocate_template, formData).then(response => {
                EziLoader.hide()
                if (response.data.success !== undefined && response.data.success) {
                    toast.success("Template assigned successfully");
                    setSelectedTemplates([])
                    navigate(`/companies`);
                } else {
                    toast.warn(response.data.message)
                }
            }).catch(err => {
                EziLoader.hide()
                console.log(err.toString())
            })
        } else {
            toast.warn("please select template");
        }
    }

    const handleCheckAllTemplate = ({ target }) => {
        let isChecked = target.checked;
        if (isChecked) {
            let allTemplates = categoryTemplates.filter(item => item.ispublished).map(item => ({ id: item.id, category_id: item.category_id }))
            let combined = [...selectedTemplates, ...allTemplates];
            let updatedOptUnique = [...combined.reduce((map, val) => {
                if (!map.has(val.id)) {
                    map.set(val.id, val);
                }
                return map;
            }, new Map()).values()]
            setSelectedTemplates(updatedOptUnique)
        } else {

            let templates = categoryTemplates.map(item => item.id)
            let newTemplates = selectedTemplates.filter(item => !templates.includes(item.id))
            setSelectedTemplates([...newTemplates])
        }
    }

    const handleCheckTemplate = (target, data) => {
        let isChecked = target.checked;
        if (isChecked) {
            let combined = [...selectedTemplates, { id: data.id, category_id: data.category_id }];
            let updatedOptUnique = [...combined.reduce((map, val) => {
                if (!map.has(val.id)) {
                    map.set(val.id, val);
                }
                return map;
            }, new Map()).values()]
            setSelectedTemplates(updatedOptUnique)
        } else {
            let newTemplates = selectedTemplates.filter(item => item.id !== data.id)
            setSelectedTemplates(newTemplates)
        }
    }
    const isSelected = (id) => {
        return selectedTemplates.length > 0 && selectedTemplates.map(item => item.id).includes(id)
    }

    const isCheckedAll = () => {
        let allData = categoryTemplates.map(item => item.id)
        let selectedTemp = selectedTemplates.filter(item => allData.includes(item.id))
        return selectedTemp.length === allData.length
    }
    useEffect(() => {
        EziLoader.show()
        getCategoryTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCategory]);
    useEffect(getCategoriesListing, []);

    return (
        <React.Fragment>
            <section className="Page-CompaniesTemplateDashboard">
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate("/companies")}>
                            Companies
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate(-1)}>{defaultCategory.category_name}</Breadcrumb.Item>
                        <Breadcrumb.Item>Templates</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="template-category-dropdown" >
                        <CategoryFilter handleSelect={changeTemplatesCategory} data={categories} defaultSelected={defaultCategory.category_name} />
                    </div>
                    <span className="template-category-subtitle"> {(defaultCategory.description !== undefined) ? defaultCategory.description : ''} </span>
                </div>
                <div className="cat-search-count-wrap">
                    <div className="cat-temp-count-wrap">
                        Selected Template
                        <span className="cat-temp-count">{selectedTemplates.length || 0}</span>
                    </div>
                    <SweetSearch loading={searchLoading} change={handleFilterSearch} ref={inputSearch} />
                </div>
                <div className="category-dashboard-table">
                    <div className="category-dashboard-table-row category-table-heading">
                        <div className="category-dashboard-table-cell">
                            <label className="ezi-checkbox">
                                <input type="checkbox" onChange={handleCheckAllTemplate} checked={isCheckedAll()} />
                                <span className="ezi-checkbox-mark"></span>
                            </label>
                        </div>
                        <div className="category-dashboard-table-cell"> Template Name </div>
                        <div className="category-dashboard-table-cell"> Owner </div>
                        <div className="category-dashboard-table-cell"> Status </div>
                        <div className="category-dashboard-table-cell"> Active </div>
                        <div className="category-dashboard-table-cell"> Template Allocated </div>
                        <div className="category-dashboard-table-cell">
                            <button type="button" className="ezi-btn btn-ripple ezi-pink-btn allocate-save" onClick={() => { allocateTemplateToCompanies() }}>Save</button>
                        </div>
                    </div>
                    {
                        categoryTemplates.length > 0 ? categoryTemplates.map((item, index) => {
                            return (
                                <div key={index} className="category-dashboard-table-row">
                                    <div className="category-dashboard-table-cell" data-title="Checkbox">
                                        <label className="ezi-checkbox">
                                            <input type="checkbox" onChange={({ target }) => { if (!item.ispublished) { toast.error("Please Published The Template") } else { handleCheckTemplate(target, item) } }} checked={isSelected(item.id)} title={`${!item.ispublished ? 'Template is Unpublished' : ''}`} />
                                            <span className="ezi-checkbox-mark"></span>
                                        </label>
                                    </div>
                                    <div className="category-dashboard-table-cell" data-title="Template Name">
                                        <div className={`category-table-template-wrap `} onClick={() => {

                                            navigate(`/template-dashboard/edit-template/${(defaultCategory.category_id !== "") ? defaultCategory.category_id : item.category_id}/${item.id}`, {
                                                state:{action: "edit-template",
                                                category_name: defaultCategory.category_name}
                                            })

                                        }}>
                                            <div className="category-table-template-text-wrap">
                                                <span className="category-table-template-name">{item.name}</span>
                                                <span className="category-table-create">Last Modified: {getFirstWord(item.updated_at)}   |   Created on: {getFirstWord(item.created_at)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="category-dashboard-table-cell" data-title="Owner"> {item.owner_name || "Dummy"} </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Status"> {(item.ispublished) ? "Published" : "Unpublished"} </div>
                                    <div className="category-dashboard-table-cell table-status" data-title="Is Active"><span className={`isactive ${item.status}`}>{getFirstWord(item.status)}</span></div>

                                    <div className="category-dashboard-table-cell table-status" data-title="Template Allocated">
                                        <span>{item.is_template_allocated}</span>
                                    </div>
                                </div>
                            )
                        }) : <h4 style={{ textAlign: "center" }}>No result Found</h4>
                    }
                </div>
                <div className="pagination-plugin-wrap category-pagination-formatter">
                    <Pagination
                        activePage={pagination.current_page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={pagination.total || 0}
                        onChange={handlePagination}
                        hideDisabled={true}
                    />
                </div>
            </section>
        </React.Fragment>
    )
}

export default AllocateTemplate;