import React, { useState, useEffect, useContext } from "react";
import './SMTPSetting.scss';
import { Breadcrumb } from 'react-bootstrap';
import useForm from 'react-hook-form';
import configURL from 'config/config';
import Axios from "utility/Axios";
import { toast } from 'react-toastify';
import AppContext from 'store/AppContext';

const SMTPSetting = () => {

    const { EziLoader, appState, languageObj } = useContext(AppContext);
    const { register, handleSubmit, reset } = useForm();
    const [companyData, setCompanyData] = useState({});
    const [companyDetails, setCompanyDetails] = useState({});
    const [isSMTP, setIsSMTP] = useState(false);
    const [password, setPassword] = useState("");
    const [isOauth, setIsOauth] = useState(false);
    const company_id = appState.current_app_detail.id || null;
    const [isClear, setIsClear] = useState(false);

    /**
     * Save Company
     * @param {Object} data 
     */
    const submitFormData = (data) => {
        // console.log(Object.keys(companyData))
        // if (data.smtpauth === "") {
        //     toast.warn('Please Select SMTP Auth');
        //     return;
        // }
        EziLoader.show();
        let newData = {};
        const cleanedConfig = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value.trim() !== "")
        );
        newData.smtp = cleanedConfig;
        if(password !== ""){
            newData.smtp.password = password;
        }
        if (isOauth) {
            newData.smtp.is_oauth = true;
            newData.smtp.tenant_id = data.tenant_id || "";
            newData.smtp.client_id = data.client_id || "";
            newData.smtp.client_secret = data.client_secret || "";
        } else {
            newData.smtp.is_oauth = false;
        }
        const sendData = new FormData();
        sendData.append(" communication_setting", JSON.stringify(newData));
        Axios.post(configURL.communication_settings, sendData).then(response => {
            if (response.data.success === true) {
                toast.success(response.data.message || 'SMTP Details Saved Successfully');
                setIsSMTP(false);
                EziLoader.hide();
            } else {
                toast.warn(response.data.message || languageObj.translate('report_section.something_went_wrong'));
                EziLoader.hide();
            }
        }).catch(err => {
            console.log(err.toString());
            EziLoader.hide()
        })
    }

    const sendTestMail = (data) => {
        EziLoader.show();
        let formData = new FormData()
        formData.append("user_email", data.smtp_email)
        Axios.post(configURL.verify_smtp_settings, formData).then(response => {
            if (response.data.success === true) {
                toast.success(response.data.message);
                reset({
                    smtp_email: ""
                })  
                EziLoader.hide();
            } else {
                EziLoader.hide();
                toast.warn(response.data.message || languageObj.translate('report_section.something_went_wrong'));
            }
        })
    }

    const getSMTPDetails = () => {
        if (company_id) {
            EziLoader.show();
            let formData = new FormData();
            Axios.post(configURL.get_communication_settings, formData).then(response => {
                if (response.data.success) {
                    setCompanyData(response.data.communication_setting.smtp || '');
                    setCompanyDetails(response.data.communication_setting.smtp || '');
                    setIsSMTP(false);
                    setIsOauth(response?.data?.communication_setting?.smtp?.is_oauth);
                    !response.data.communication_setting && toast.warn(response.data.message || languageObj.translate('report_section.something_went_wrong'));
                    EziLoader.hide();
                    setIsClear(false);
                } else {
                    EziLoader.hide();
                    toast.warn(response.data.message || languageObj.translate('report_section.something_went_wrong'));
                }
            }).catch(err => {
                console.log(err.toString());
                EziLoader.hide();
            })
        }
    }
    
    const handleInputPassword = (password) => {
        const trimmedPassword = password.trim();
        setPassword(trimmedPassword);
    };
      
    useEffect(() => {
        getSMTPDetails();
    }, []);

    const handleToggleOauth=() => {
        setIsOauth(!isOauth)
        reset({
            tenant_id: "",
            client_id: "",
            client_secret: ""
        })
    }

    /**
     * Handle Form Reset
     */
    const clearForm = () => {
        reset({
            from_name: "",
            from_email_address: "",
            host: "",
            port: "",
            username: "",
            password: "",
            encryption: "",
            smtpauth: "",
            tenant_id: "",
            client_id: "",
            client_secret: "",
            smtp_email: ""
        })
        setIsOauth(false);
        setIsClear(true);
    }
    
    return (
        <React.Fragment>
            <section className="Page-SMTPsetting" >
                <form onSubmit={handleSubmit(submitFormData)} className="company-form">
                    <div className="breadcrumb_ezi">
                        <Breadcrumb>
                            <Breadcrumb.Item>Settings</Breadcrumb.Item>
                            <Breadcrumb.Item>SMTP Setting</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="column-header">
                            <h1 className="page-heading">SMTP Details</h1>
                            <div className="column-header-btn">
                                <button type="button" className="btn-ripple clear_all" onClick={clearForm} >Clear all</button>
                                <button type="submit" className="btn-ripple add-new">Save setting</button>
                            </div>
                        </div>
                    </div>
                    <div className="add-company-form-wrap">
                        <div className="company-info-header">
                            <div className="conpany-name-id-wrap">
                                <span className="title">SMTP Details</span>
                                <span className="subtitle">Please fill below details to send system mails from entered email id.</span>

                            </div>
                        </div>
                        <div className="smtp-outh-toggle">
                        <label>Enable Auth</label>
                            <div className={`ezi-tabular-toggle ${isOauth ? "on" : "off"}`} data-label={isOauth ? "ON" : "OFF"} data-placeholder={isOauth ? "OFF" : "ON"} onClick={() => handleToggleOauth()} > </div>
                        </div>
                        <div className="tablist_ezi">
                            <div className="add-company-field-wrapper">
                                <label>
                                    <input type="text"
                                        placeholder='From name'
                                        name="from_name"
                                        ref={register({ required: isOauth && !isClear })}
                                        defaultValue={companyData.from_name || ""} />
                                </label>
                                <label>
                                    <input type="email"
                                        placeholder='From Email Address'
                                        name="from_email_address"
                                        ref={register({ required: isOauth })}
                                        defaultValue={companyData.from_email_address || ""} />
                                </label>
                                <label>
                                    <input type="text"
                                        placeholder='Host'
                                        name="host"
                                        ref={register({ required: isOauth })}
                                        defaultValue={companyData.host || ""} />
                                </label>
                                <label>
                                    <input type="text"
                                        placeholder='Port'
                                        name="port"
                                        ref={register({ required: isOauth })}
                                        defaultValue={companyData.port || ""} />
                                </label>
                                <label className="auth">
                                    <select name="smtpauth" placeholder="SMTP Auth" value={companyData.smtpauth || ""} ref={register}
                                        onChange={({ target }) => {
                                            setCompanyData({ ...companyData, smtpauth: target.value })
                                        }}>
                                        <option value="">SMTP Auth</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </label>
                                <label className="encryption">
                                    <input type="text"
                                        placeholder='Encryption'
                                        name="encryption"
                                        ref={register({ required: isOauth })}
                                        defaultValue={companyData.encryption || ""} />
                                </label>

                                <label>
                                    <input type="text"
                                        placeholder='User name'
                                        name="username"
                                        ref={register({ required: isOauth })}
                                        defaultValue={companyData.username || ""} />
                                </label>
                                <label>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        autoComplete="new-password"
                                        onChange={({ target }) => handleInputPassword(target.value)}
                                    />
                                </label>
                                {isOauth && (
                                    <>
                                        <label>
                                            <input
                                                type="text"
                                                placeholder="Tenant ID"
                                                name="tenant_id"
                                                ref={register}
                                                defaultValue={companyData.tenant_id || ""}
                                            />
                                        </label>
                                        <label>
                                            <input
                                                type="text"
                                                placeholder="Client ID"
                                                name="client_id"
                                                ref={register}
                                                defaultValue={companyData.client_id || ""}
                                            />
                                        </label>
                                        <label>
                                            <input
                                                type="text"
                                                placeholder="Client Secret"
                                                name="client_secret"
                                                ref={register}
                                                // defaultValue={companyData.client_secret || ""}
                                            />
                                        </label>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
                <form onSubmit={handleSubmit(sendTestMail)}>
                    <div className={`add-company-form-wrap ${isSMTP === true ? 'disabled-input-email' : ''}`}>
                        <div className={`email-field-wrapper ${isSMTP === true ? 'mb-2' : ''}`}>

                            <h3 className="email-heading">Check Test Mail</h3>

                            <div className="email-field-section">
                                <label className="test-email mb-0">{isSMTP === true ? <input type="email" className="email-inp disabled-input-email" placeholder="Enter Email Address"
                                    name="smtp_email"
                                    disabled={isSMTP} />
                                    : <input type="email" className="email-inp" ref={register({ required: false })} placeholder="Enter Email Address"
                                        name="smtp_email"
                                        disabled={isSMTP} />}
                                </label>
                                <div className={`send-button ${isSMTP === true ? 'disabled-input-email' : ''}`}>
                                    <button className="send-btn" disabled={isSMTP || Object.keys(companyData).length === 0} type="send">Send</button>
                                </div>
                            </div>
                        </div>
                        {isSMTP === true ? <span className="note-msg">Send Test Mail</span> : <span></span>}
                    </div>
                </form>
                <div className="add-company-form-wrap instruction">
                    <label>Here’s a list of all of them and what do they configure:</label>
                    <b>From Email</b> the email address you want to send emails from – for example, email@yourdomain.com;<br />
                    <b>From Name</b> the name that your emails will be sent from;<br />
                    <b>SMTP Host</b>the hostname for your SMTP server;<br />
                    <b>SMTP Port</b>the port your server works on;<br />
                    <b>Encryption</b>if you have SSL/TLS encryption available for that hostname, enter it here;<br />
                    <b>Username</b>the username for your SMTP server;<br />
                    <b>Password</b>the password for your SMTP server;<br /><br />
                    <b>Once you configure those parameters, click Save Settings at the top of the page.</b>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SMTPSetting