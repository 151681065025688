import Dashboard from 'pages/Dashboard';
import SaveCompany from 'pages/Companies/SaveCompany'
import EditCompany from 'pages/Companies/EditCompany';
import Companies from 'pages/Companies/Companies';
import AllocateTemplate from 'pages/TemplateAllocation/AllocateTemplate';
import Themes from 'pages/Themes/Themes';
import Subthemes from 'pages/Themes/Subthemes';
import TemplateDashboard from 'pages/TemplateDashboard/TemplateDashboard';
import TemplateCategory from 'pages/TemplateCategory/TemplateCategory';
import AddCategory from 'pages/TemplateCategory/AddCategory';
import TemplateAdd from 'pages/TemplateDashboard/TemplateAdd';
import TemplateBuilder from 'pages/TemplateBuilder/TemplateBuilder';
import CategoryTemplateDashboard from 'pages/TemplateDashboard/CategoryTemplateDashboard'
import UserProfile from 'pages/Profile/UserProfile';
import CommunicationTemplates from 'pages/CommunicationTemplates/CommunicationTemplates';
import SaveCommunicationTemplate from 'pages/CommunicationTemplates/SaveCommunicationTemplate';
import UserCreate from 'pages/User/User'
import NewUser from 'pages/User/SaveUser'
import QuestionBank from 'pages/Companies/QuestionBank'
import AssignAdvancedReport from 'pages/Companies/AssignAdvancedReport'
import BenchmarkListing from 'pages/Companies/BenchamarkListing';
import SubBenchmark from 'pages/Companies/SubBenchmark';
import BenchmarkBulkUpload from 'pages/Companies/BenchmarkBulkUpload';
import SMTPSetting from 'pages/SMTPSetting/SMTPSetting';
import ManagerPractices from 'pages/components/ManagerPractices';

const router = [
    { path: '/dashboard', component: Dashboard, main_user: false, skip_access: true },
    { path: '/add-company', component: SaveCompany, main_user: false, skip_access: true },
    { path: '/update-company/:account_id', component: SaveCompany, main_user: false, skip_access: true },
    { path: '/companies', component: Companies, main_user: false, skip_access: true },
    { path: '/edit-company', component: EditCompany, main_user: false, skip_access: true },
    { path: '/company-templates/:account_id', component: AllocateTemplate, main_user: false, skip_access: true },
    { path: '/themes', component: Themes, main_user: false, skip_access: true },
    { path: '/subthemes/:category_id/:theme_id', component: Subthemes, main_user: false, skip_access: true },
    { path: '/template-dashboard', component: TemplateDashboard, main_user: false, skip_access: true },
    { path: '/template-dashboard/categories', component: TemplateCategory, main_user: false, skip_access: true },
    { path: '/template-dashboard/add-category', component: AddCategory, main_user: false, skip_access: true },
    { path: '/template-dashboard/add-new/:category_id', component: TemplateAdd, main_user: false, skip_access: true },
    { path: '/template-dashboard/category-templates', component: CategoryTemplateDashboard, main_user: false, skip_access: true },
    { path: '/template-dashboard/edit-template/:category_id/:template_id', component: TemplateAdd, main_user: false, skip_access: true },
    { path: '/template-dashboard/template-questions/:category_id', component: TemplateBuilder, main_user: false, skip_access: true },
    { path: '/template-dashboard/template-questions/:category_id/:template_id', component: TemplateBuilder, main_user: false, skip_access: true },
    { path: '/user-profile', component: UserProfile, main_user: false, skip_access: true },
    { path: '/communication-templates', component: CommunicationTemplates, main_user: false, skip_access: true },
    { path: '/communication-templates/save/:template_id?', component: SaveCommunicationTemplate, main_user: false, skip_access: true },
    { path: '/partner-user', component: UserCreate, main_user: true, skip_access: false },
    { path: '/add-new-user', component: NewUser, main_user: true, skip_access: false },
    { path: '/question-bank', component: QuestionBank, main_user: false, skip_access: true },
    { path: '/assign-advanced-report/:account_id?', component: AssignAdvancedReport,  main_user: false, skip_access: true },
    { path: '/benchmark-listing/:account_id?',component: BenchmarkListing, main_user: false, skip_access: true},
    { path: '/Currentbenchmarks/:account_id', component: SubBenchmark, main_user: false, skip_access: true },
    { path: '/benchmark-bulk-uplaod/:benchmark_id', component: BenchmarkBulkUpload, main_user: false, skip_access: true },
    { path: '/smtp-setting', component: SMTPSetting, main_user: false, skip_access: true },
    { path: '/manager-practices', component: ManagerPractices, main_user: false, skip_access: true },
]

export default router