import React, { useContext, useEffect, useState, useRef } from "react";
import "./Users.scss";
import { Breadcrumb } from "react-bootstrap";
import AppContext from "store/AppContext";
import EziLoader from "components/EziLoader";
import Axios from "utility/Axios";
import configURL from "config/config";
import { toast } from "react-toastify";
import DataTable from "components/DataTable";
import SweetSearch from "components/SweetSearch";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const Users = (props) => {
  const { languageObj = {}, appState } = useContext(AppContext);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [dataColumn, setDataColumn] = useState([{}]);
  const per_page = 10;
  const inputSearch = useRef(null);
  var searchTimer = null;
  const navigate = useNavigate();

  const handleDeleteUser = (row = {}) => {
    let formData = new FormData();
    formData.append("delete_user_id", row.delete_user_id);
    formData.append("user_partner_id", row.user_partner_id);
    formData.append("user_account_id", row.user_account_id);
    formData.append("name", row.name);
    formData.append("email", row.email);
    formData.append("phone", row.phone);

    confirmAlert({
      title: "Delete User",
      message: "Are you sure you want to delete this ? ",
      buttons: [
        {
          label: "Confirm",
          onClick: () => {
            Axios.post(configURL.delete_user, formData).then((response) => {
              if (response.data.success === true) {
                toast.success("User Deleted!");
                getUsersList();
              } else {
                toast.warn(response.data.message);
              }
            });
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleTableChange = (type, props) => {
    setLoading(true);
    switch (type) {
      case "pagination":
        getUsersList(props.page);
        break;
      case "sort":
        getUsersList();
        break;
      default:
        break;
    }
  };

  const getUsersList = (page = 1) => {
    let formData = new FormData();
    let userSearch = inputSearch.current.value;
    formData.append("page", page);
    formData.append("per_page", per_page);
    formData.append("search", userSearch);
    Axios.post(configURL.user_list, formData).then((response) => {
      if (response.data.success === true) {
        const columnData = [
          { dataField: "name", text: "Name" },
          { dataField: "phone", text: "Phone No." },
          { dataField: "email", text: "Email" },
        ];
        columnData.push({
          dataField: "action",
          text: "Action",
          headerFormatter: headingFormatter,
          formatter: actionFormatter,
        });
        setDataColumn(columnData);
        setUsersData(response.data.result);
        setPagination(response.data.pagination);
        setLoading(false);
        setSearchLoading(false);
      } else {
        toast.warn(response.data.message);
      }
    });
  };

  const handleTableSearch = () => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      setSearchLoading(true);
      getUsersList();
    }, 500);
  };

  const headingFormatter = (column, colIndex) => {
    return <div className="action-heading-name">Action</div>;
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="dt-bootstrap-edit-delte-wrap">
        <button
          type="button"
          className="dt-bootstrap-delete_ic"
          onClick={() => {
            handleDeleteUser(row);
          }}
          disabled={appState.user && appState.user.id === row.id}
          title={
            appState.user && appState.user.id === row.id ? "Logged In" : ""
          }
        >
          Delete
        </button>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <section className="Page-Users">
        <div className="breadcrumb_ezi">
          <Breadcrumb>
            <Breadcrumb.Item>
              {languageObj.translate("Settings.1")}
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate("/partner-user")}>
              User Management
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="column-header">
            <h1 className="page-heading">{languageObj.translate("Users.1")}</h1>
            <div className="column-header-btn">
              <button
                type="button"
                className="btn-ripple add-new"
                onClick={() => {
                  navigate("/add-new-user", {
                    state:{action: "add",}
                  });
                }}
              >
                {languageObj.translate("Addnew.1")}
                <span className="add_ic"></span>
              </button>
            </div>
          </div>
        </div>
        <div className="users-table-content">
          <SweetSearch
            ref={inputSearch}
            change={handleTableSearch}
            loading={searchLoading}
          />
          {usersData.length > 0 && dataColumn.length > 0 && (
            <div className="saved_participants_table">
              <DataTable
                data={usersData}
                columns={dataColumn}
                hideSelectAll
                page={pagination.current_page}
                sizePerPage={per_page}
                onTableChange={handleTableChange}
                keyField="id"
                totalSize={pagination.total}
                hideSelect
              />
            </div>
          )}
          {usersData.length === 0 && (
            <div className="saved_participants_table">
              <DataTable data={[]} columns={[{}]} keyField="id" hideSelect />
            </div>
          )}
        </div>
      </section>
      {loading && <EziLoader />}
    </React.Fragment>
  );
};

export default Users;
