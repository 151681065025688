import React, { useState, useEffect, useRef, useContext } from "react";
import './Themes.scss';
import { Breadcrumb, Tab, Nav } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import useOutsideClick from "hooks/useOutsideClick";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from "utility/Axios";
import configURL from 'config/config';
import AddThemeModal from '../components/AddThemeModal';
import EditThemeModel from '../components/EditThemeModel';
import AppContext from 'store/AppContext';
import EziLoader from "components/EziLoader";
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";


const Themes = (props) => {
    const { languageObj = {} } = useContext(AppContext)
    const [themeModal, setThemeModal] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModelData, setEditModelData] = useState({});
    const ref = useRef();
    const [dropdownFlag, setDropdownFlag] = useState(false)
    const [category, setCategory] = useState({});
    const [categoryDs, setCategoryDs] = useState([]);
    const [themes, setThemes] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [activeTab, setActiveTab] = useState('template');
    const navigate = useNavigate();

    const dropdown = () => {
        setSearchTerm('');
        setDropdownFlag(!dropdownFlag);
    }

    const redirectToSubTheme = (rowIndex) => {
        let theme = themes[rowIndex];
        navigate(`/subthemes/${category.id}/${theme.id}`, {
            state:{category: category.category_name,
            name: theme.name,
            description: theme.description,
            type: activeTab}
        });
    }

    const statusFormatter = (cell, row) => {
        return (
            <span className={`themes-status_c ${row.status === "active" ? "active" : "offline"}`}>{cell}</span>
        );
    }

    const themeNameFormatter = (cell, row) => {
        return (
            <span className="theme-name_c">{cell}</span>
        );
    }

    const AddNewFormatter = (column, colIndex) => {
        return (
            <div className="add-theme-btn-wrap">
                <button type="button" className={`btn-ripple ezi-pink-btn add-theme-btn`} onClick={() => {

                    setThemeModal(true)

                }}>{column.text} <span className="add_ic"></span></button>
            </div>
        );
    }

    const actionFormatter = (cell, row) => {
        row.category_id = category.id
        return (
            <div className="theme-editDelete-wrap">
                <button type="button" className={`theme-edit`} onClick={() => {
                    setEditRowData(row)
                }}>Edit</button>
                <button type="button" className={`theme-delete`} onClick={() => {
                    deleteEntry(row.id)
                }}>Delete</button>
            </div>
        );
    }

    const columns = [
        {
            dataField: "name", text: "themes",
            formatter: themeNameFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    redirectToSubTheme(rowIndex);
                }
            },

        },
        { dataField: "description", text: "Description", classes: 'theme-des' },
        { dataField: "status", text: "Status", formatter: statusFormatter },
        { dataField: 'theme', text: 'Add new theme', headerFormatter: AddNewFormatter, formatter: actionFormatter },
    ];

    const changeCategory = (categoryObj) => {
        setDropdownFlag(!dropdownFlag);
        setCategory(categoryObj);
        setThemeListing(categoryObj);
    }
    const changeTab = (tab) => {
        setActiveTab(tab);
        getInitialCatgory(tab)
    }
    const setThemeListing = (categoryObj, pageno = 1, themeType = activeTab) => {
        setLoading(true)
        setEditModalVisible(false);
        let formData = new FormData();
        formData.append("category_id", categoryObj.id);
        formData.append("page", pageno);
        formData.append("type", themeType);
        Axios.post(configURL.partner_themeListing, formData).then(res => {
            setLoading(false)
            if (res.data.success !== undefined && res.data.success) {
                setPagination(res.data.pagination);
                setThemes(res.data.result)
            } else {
                setThemes([]);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    };

    const searchCategory = (e) => {
        setSearchTerm(e.target.value);
    }

    useOutsideClick(ref, () => {
        dropdownFlag && setDropdownFlag(false);
    });

    const toggleEditThemeModal = () => {
        setEditModalVisible(!editModalVisible);
    }

    const setEditRowData = (rowData) => {
        toggleEditThemeModal();
        setEditModelData(rowData);
    }

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="id"
            data={themes} columns={columns}
            noDataIndication="Table is Empty"
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
            rowClasses="theme-datatable-row"
        />
    );

    const handleAllEntriesChange = (type, { page, sizePerPage }) => {
        if (type === 'pagination') {
            setThemeListing(category, page);
        }
    }

    const deleteEntry = (rowId) => {
        confirmAlert({
            title: 'Delete Theme',
            message: `Are you sure ? \nOnce you delete the Theme, Corresponding theme will be deleted from template as well.`,
            buttons: [
                {
                    label: 'Okay',
                    onClick: () => {
                        if (rowId !== null) {
                            let formData = new FormData();
                            formData.append("id", rowId);
                            formData.append("model", 'se_themes');
                            Axios.post(configURL.partner_deleteRow, formData).then(resposnse => {
                                if (resposnse !== undefined && resposnse.status) {
                                    setThemeListing(category);
                                }
                            });
                        }
                    }
                },
                { label: 'Cancel' }
            ]
        });
    }

    const getInitialCatgory = (tab = activeTab) => {
        setLoading(true)
        let formData = new FormData();
        formData.append("type", tab)
        Axios.post(configURL.partner_categoryDs, formData).then(res => {
            setLoading(false)
            if (res.data.result && res.data.result.length > 0) {
                setCategoryDs(res.data.result);
                let categoryObj = res.data.result[0];
                setCategory(categoryObj);
                setThemeListing(categoryObj, 1, tab);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }

    useEffect(getInitialCatgory, []);

    useEffect(() => {
        const results = categoryDs.filter(ele => {
            return ele.category_name.toLowerCase().includes(searchTerm.toLowerCase())
        });
        setSearchResults(results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    let categoryList = (searchTerm !== '') ? searchResults : categoryDs;

    return (
        <React.Fragment>
            <section className="Page-Themes" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item >{languageObj.translate('Themes.1')}</Breadcrumb.Item>
                        <Breadcrumb.Item >{activeTab}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="themes-dropdown" >
                        <button type="button" onClick={dropdown} className={`themes-dropdown-btn ${category.category_name ? "selected" : ""}`}> {(category.category_name) ? category.category_name : languageObj.translate('SelectCategory.1')} </button>
                        <div className={`themes-inner-dropdown ${dropdownFlag ? 'active' : ""}`} ref={ref}>
                            <div className="themes-dropdown-header">
                                <input type="text" className="themes-dropdown-search" value={searchTerm} onChange={(e) => searchCategory(e)} placeholder={languageObj.translate('Search.1')} />
                                <label>{languageObj.translate('Category.1')}</label>
                            </div>
                            <ul>
                                {

                                    categoryList.map(categoryData =>
                                        <li onClick={() => changeCategory(categoryData)} key={categoryData.id}>
                                            {categoryData.category_name}
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <span className="themes-subtitle"> {(category.description !== undefined) ? category.description : ''} </span>
                </div>
                <AddThemeModal commonid={category.id} fieldname="Theme" commonidname="category_id" tab={activeTab} show={themeModal} onHide={() => setThemeModal(false)} name="theme" updatethemelisting={() => setThemeListing(category)} />

                <EditThemeModel show={editModalVisible} onHide={() => toggleEditThemeModal()} toggleeditmodal={() => toggleEditThemeModal()} tab={activeTab} name="Theme" editdata={editModelData} updatethemelisting={() => setThemeListing(category)} />
                <div className="themes-content">

                    <div className="ezi-sweet-tab">
                        <Tab.Container defaultActiveKey={activeTab} onSelect={k => changeTab(k)}>
                            <div className="sweet-tab-left-header">
                                <Nav variant="pills" >
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="survey" >Survey</Nav.Link>
                                    </Nav.Item> */}
                                    <Nav.Item>
                                        <Nav.Link eventKey="template">Template</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>

                            <Tab.Content>

                                <Tab.Pane eventKey="survey">
                                    <RemotePagination
                                        data={themes}
                                        page={pagination.current_page}
                                        sizePerPage={pagination.per_page}
                                        totalSize={pagination.total}
                                        onTableChange={handleAllEntriesChange}
                                    />
                                </Tab.Pane>

                                <Tab.Pane eventKey="template">
                                    <RemotePagination
                                        data={themes}
                                        page={pagination.current_page}
                                        sizePerPage={pagination.per_page}
                                        totalSize={pagination.total}
                                        onTableChange={handleAllEntriesChange}
                                    />
                                </Tab.Pane>

                            </Tab.Content>

                        </Tab.Container>
                    </div>

                </div>
            </section>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default Themes;