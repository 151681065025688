import React, { useState, useEffect, useRef } from "react";
import './CommunicationTemplates.scss';
import { Breadcrumb, Tab, Nav } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from "utility/Axios";
import configURL from 'config/config';
import EziLoader from "components/EziLoader";
import { confirmAlert } from 'react-confirm-alert';
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const CommunicationTemplates = () => {
    const [communicationTemp, setCommunicationTemp] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location?.state?.templateType.toLowerCase() || 'email');
    const perPage = useRef(10);
    const navigate = useNavigate();

    const addNewTemplate = () => {
        navigate('/communication-templates/save', {
            state:{ communicationType: communicationTemp.filter(item => item.name === activeTab.toUpperCase())[0],
            template_type: activeTab,}
        })
    }

    const editTemplate = (id = null) => {
        navigate(`/communication-templates/save/${id}`, {
           state: { template_type: activeTab,}
        })
    }

    const AddNewFormatter = (column, _) => {
        return (
            <div className="add-communication-template-btn-wrap">
                <button type="button" className={`btn-ripple ezi-pink-btn add-communication-template-btn`} onClick={addNewTemplate}>{column.text} <span className="add_ic"></span></button>
            </div>
        );
    }

    const actionFormatter = (_, row) => {
        return (
            <div className="communication-template-editDelete-wrap">
                <button type="button" className={`communication-template-edit`} onClick={() => editTemplate(row.id)}>Edit</button>
                {/* <button type="button" className={`communication-template-delete`} onClick={() => deleteEntry(row.id)}>Delete</button> */}
            </div>
        );
    }

    const columns = [
        { dataField: "name", text: "Template Name" },
        { dataField: "subject", text: "Subject" },
        // { dataField: "status", text: "Status" },
        { dataField: 'add', text: 'Add New', headerFormatter: AddNewFormatter, formatter: actionFormatter },
    ];

    const getCommunicationTemplates = (page = 1, type = activeTab) => {
        setLoading(true)
        let formData = new FormData();
        formData.append("page", page);
        formData.append("template_type", type);
        formData.append("per_page", perPage.current);
        Axios.post(configURL.get_communication_templates, formData).then(res => {
            if (res.data.success !== undefined && res.data.success) {
                setPagination(res.data.pagination);
                setTemplates(res.data.result);
                setLoading(false);
            } else {
                setTemplates([]);
                setLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="id"
            data={data} columns={columns}
            noDataIndication="Table is Empty"
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
            rowClasses="communication-template-datatable-row"
        />
    );

    const handleAllEntriesChange = (type, { page }) => {
        if (type === 'pagination') {
            getCommunicationTemplates(page, activeTab);
        }
    }

    const deleteEntry = (rowId) => {
        confirmAlert({
            title: 'Delete Template',
            message: `Are you sure ? You want to delete this communication template.`,
            buttons: [
                {
                    label: 'Okay',
                    onClick: () => {
                        if (rowId !== null) {
                            let formData = new FormData();
                            formData.append("id", rowId);
                            formData.append("template_type", activeTab);
                            Axios.post(configURL.delete_communication_template, formData).then(resposnse => {
                                if (resposnse?.data?.success === true) {
                                    toast.success(resposnse?.data?.message || 'Template Deleted.');
                                    getCommunicationTemplates(1, activeTab);
                                } else {
                                    toast.warn(resposnse?.data?.message || 'Something went wrong. Please try again');
                                }
                            });
                        }
                    }
                },
                { label: 'Cancel' }
            ]
        });
    }

    const getInitialCatgory = () => {
        setLoading(true)
        let formData = new FormData();
        Axios.post(configURL.communication_medium_list, formData).then(res => {
            if (res.data.result && res.data.result.length > 0) {
                // let categoriesData = []
                // res.data.result.forEach(item => {
                //     categoriesData.push({ id: item.id, name: item.category_name, description: item.description });
                // });
                // setCategories(categoriesData);
                // let categoryObj = categoriesData[0];
                setCommunicationTemp(res.data.result);
                getCommunicationTemplates(1);
                setLoading(false);
            }else{
                setLoading(false);
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }

    useEffect(() => {
        location?.state?.templateType && setActiveTab(location?.state?.templateType.toLowerCase());
    }, [location?.state?.templateType]);

    useEffect(getInitialCatgory, []);

    return (
        <React.Fragment>
            <section className="Page-CommunicationTemplates" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item >Communication Templates</Breadcrumb.Item>
                        <Breadcrumb.Item >{activeTab}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="communication-template-content">
                    <div className="ezi-sweet-tab">
                        <Tab.Container defaultActiveKey={activeTab} onSelect={tab => {
                            setActiveTab(tab)
                            getCommunicationTemplates(1, tab)
                        }}>
                            <div className="sweet-tab-left-header">
                                <Nav variant="pills" >
                                    {communicationTemp.length > 0 && communicationTemp.map((item, index) => <Nav.Item key={index}>
                                        <Nav.Link eventKey={item.name.toLowerCase()} >{item.name}</Nav.Link>
                                    </Nav.Item>)}
                                </Nav>
                            </div>
                            <Tab.Content>
                                <RemotePagination
                                    data={templates}
                                    page={pagination.current_page}
                                    sizePerPage={perPage.current}
                                    totalSize={pagination.total}
                                    onTableChange={handleAllEntriesChange}
                                />
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
            {loading && <EziLoader />}
        </React.Fragment>
    )
}

export default CommunicationTemplates;