import React, { useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux";
import { Breadcrumb } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate, useLocation } from "react-router-dom";
import AppContext from 'store/AppContext';
import configURL from 'config/config';
import SweetSearch from "components/SweetSearch";
import './ManagerPractices.scss';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Axios from "utility/Axios";
/**Text Editor Import Start */
// import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import "assets/scss/_htmleditor.scss";
/**Text Editor Import End */
import { confirmAlert } from 'react-confirm-alert';



const ManagerPractices = () => {
    const { EziLoader } = useContext(AppContext)
    const [practicesID, setPracticesID] = useState();
    const [searchLoading, setSearchLoading] = useState(false);
    const [editor, setEditor] = useState({ show: false, type: "" });
    const [tableMeta, setTableMeta] = useState({
        pagination: {},
        columns: [{}],
        data: []
    });
    const per_page = 10
    let searchTimer = null
    let inputSearch = useRef(null)
    const navigate = useNavigate();
    const location = useLocation();
    // const [practicesMessage, setPracticesMessage] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(""))));
    const [heading, setHeading] = useState();
    const [showSource, setShowSource] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');

    const actionEditPractice = (row) => {
        // setPracticesMessage(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(row.description))));
        setHtmlContent(row.description);
        setEditor({ show: true, type: "Edit" });
        setHeading(row.heading || "");
        setPracticesID(row.id);
    }

    const actionFormatter = (cell, row, page) => {
        return (
            <>
                <div className="template-action-wrap">
                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                        <button type="button" className="btn-ripple ezi-pink-btn allocate_template" onClick={() => handleDeletePractices(row)}> Delete</button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                        <button type="button" className="btn-ripple ezi-pink-btn ms-2 edit_template" onClick={() => actionEditPractice(row)} > Edit</button>
                    </OverlayTrigger>
                </div>
            </>
        );
    }

    const actionHeader = (cell, row) => {
        return (
            <span className="datatable-action-text">Actions</span>
        );
    }

    const handleFilterSearch = () => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setSearchLoading(true);
            handlePracticesList(1, inputSearch.current.value);
        }, 1000);
    }

    const handleDeletePractices = (row) => {
        confirmAlert({
            title: 'Delete Manager Practice',
            message: `Are you sure ? You want to delete this ?`,
            buttons: [
                {
                    label: 'Okay',
                    onClick: () => {
                        if (row.id !== null) {
                            EziLoader.show();
                            let formData = new FormData();
                            formData.append("id", row.id);
                            formData.append("account_id", location.state?.companyData?.account_id);
                            Axios.post(configURL.delete_practice, formData).then(response => {
                                if (response.data.success && response.data.success === true) {
                                    setEditor({ show: false, type: "" });
                                    inputSearch.current.value = "";
                                    handlePracticesList();
                                    toast.success(response.data.message.toString() || "Manager Practice Deleted Successfully.");
                                } else {
                                    toast.warn(response.data.message.toString() || "Something went wrong.");
                                }
                            }).finally(() => {
                                EziLoader.hide();
                            })
                        }
                    }
                },
                { label: 'Cancel' }
            ]
        });
    }

    const handlePracticesList = (page = 1, search = "") => {
        EziLoader.show();
        let formData = new FormData();
        formData.append("page", page);
        formData.append("search", search);
        formData.append("account_id", location.state?.companyData?.account_id);
        Axios.post(configURL.practices_list, formData).then(response => {
            if (response.data.success && response.data.success === true) {
                let columnData = [
                    { dataField: "heading", text: "Heading" },
                    { dataField: "created_at", text: "Created At" },
                ];
                columnData.push({ dataField: 'actions', text: '', headerFormatter: actionHeader, formatter: (cell, row) => actionFormatter(cell, row, page) })
                setTableMeta({
                    pagination: response.data.pagination,
                    columns: columnData,
                    data: response.data.response
                });
                EziLoader.hide();
                setSearchLoading(false);
                setShowSource(false);
                // setPractices(response.data.result);
                // toast.success(response.data.message.toString() || "Synced Successfully");
            } else {
                EziLoader.hide();
                toast.warn(response.data.message.toString() || "Something went wrong");
            }
        }).finally(() => {
            EziLoader.hide();
        })
    }

    const handleAddEditPractices = () => {
        if (!heading) {
            toast.warn("Please enter heading");
            return;
        }
        if (!htmlContent) {
            toast.warn("Please enter HTML source code");
            return;
        }
        EziLoader.show();
        let formData = new FormData();
        formData.append("account_id", location.state?.companyData?.account_id);
        formData.append("description", htmlContent);
        formData.append("heading", heading);
        practicesID && formData.append("id", practicesID);
        Axios.post(editor.type === "Add" ? configURL.create_practice : configURL.update_practice, formData).then(response => {
            if (response.data.success && response.data.success === true) {
                setEditor({ show: false, type: "" });
                handlePracticesList();
                setPracticesID();
                toast.success(response.data.message.toString() || "Synced Successfully");
            } else {
                toast.warn(response.data.message.toString() || "Something went wrong");
            }
        }).finally(() => {
            EziLoader.hide();
        })
    }

    const RemotePagination = ({ data, page = 1, sizePerPage, onTableChange, totalSize }) => {
        return <BootstrapTable
            remote
            keyField={"id"}
            noDataIndication="No Data Available"
            data={data}
            columns={tableMeta.columns}
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, })}
            onTableChange={onTableChange}
        />
    }

    const handleTableChange = (type, { page }) => {
        if (type === 'pagination') {
            EziLoader.show();
            handlePracticesList(page, inputSearch?.current?.value);
        }
    }

    // const toggleSource = () => {
    //     if (!showSource) {
    //         const html = draftToHtml(convertToRaw(practicesMessage.getCurrentContent()));
    //         setHtmlContent(html);
    //     } else {
    //         try {
    //             const contentBlock = htmlToDraft(htmlContent);
                
    //             if (!contentBlock) {
    //                 throw new Error('Invalid HTML content');
    //             }
    //             const contentState = ContentState.createFromBlockArray(
    //                 contentBlock.contentBlocks,
    //                 contentBlock.entityMap
    //             );
    //             setPracticesMessage(EditorState.createWithContent(contentState));
    //         } catch (error) {
    //             console.error('HTML Conversion Error:', error);
    //             setPracticesMessage(EditorState.createEmpty());
    //         }
    //     }
    //     setShowSource(!showSource);
    // };

    const handleBack = () => {
        setEditor({ show: false, type: "" });
        // setPracticesMessage(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(""))));
        setHtmlContent('');
        setHeading();
        setShowSource(false);
        handlePracticesList();
    }

    const handleHtmlChange = (e) => {
        // try {
        //     const contentBlock = htmlToDraft(e.target.value);
            
        //     if (!contentBlock) {
        //         throw new Error('Invalid HTML content');
        //     }
        //     const contentState = ContentState.createFromBlockArray(
        //         contentBlock.contentBlocks,
        //         contentBlock.entityMap
        //     );
        //     setPracticesMessage(EditorState.createWithContent(contentState));
        // } catch (error) {
        //     console.error('HTML Conversion Error:', error);
        //     setPracticesMessage(EditorState.createEmpty());
        // }
        setHtmlContent(e.target.value);
    };

    useEffect(() => {
        handlePracticesList();
    }, [location.state?.companyData?.account_id]);

    return (
        <React.Fragment>
            <section className="Page-ManagerPractices" >
                <div className="breadcrumb_ezi">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate("/dashboard")}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate("/companies")}>Companies</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => handleBack()}>Manager Practices</Breadcrumb.Item>
                        {editor.show && <>
                            {editor.type === "Add" && <Breadcrumb.Item>Add New Practices</Breadcrumb.Item>}
                            {editor.type === "Edit" && <Breadcrumb.Item>Edit Practices</Breadcrumb.Item>}
                        </>}
                    </Breadcrumb>
                    <div className="column-header">
                        <h1 className="page-heading">Manager Practices</h1>
                        <div className="column-header-btn">
                            {editor.show ? <button type="button" className="btn-ripple add-new" onClick={handleAddEditPractices}>Save</button> : <button type="button" className="btn-ripple add-new" onClick={() => { setEditor({ show: true, type: "Add" }); setHtmlContent(''); setHeading(); }}>Add New Practice</button>}
                        </div>
                    </div>
                </div>
                {!editor.show && <><div className="filter-search-wrap">
                    <SweetSearch loading={searchLoading} ref={inputSearch} change={handleFilterSearch} />
                </div>
                    <div className="users-table-content overflow-hidden">
                        <RemotePagination
                            data={tableMeta.data}
                            page={tableMeta.pagination.current_page}
                            sizePerPage={per_page}
                            totalSize={tableMeta.pagination.total}
                            onTableChange={handleTableChange}
                        />
                    </div></>}
                {editor.show && <>
                    <div className="filter-search-wrap">
                        <input type="text" placeholder="Heading" value={heading} onChange={(e) => setHeading(e.target.value)} />
                    </div>
                    {/* <Editor
                        wrapperClassName="ezi-editor-wrapper"
                        editorClassName="ezi-editor-inner"
                        toolbarClassName="ezi-editor-toolbar"
                        editorState={practicesMessage}
                        placeholder='Manager Practice description...'
                        toolbar={
                            {
                                sourceCode: {
                                    icon: <span>HTML</span>,
                                    callback: toggleSource,
                                }
                            }
                        }
                        onEditorStateChange={setPracticesMessage}
                        toolbarCustomButtons={[
                            <button title='Source Code' className='ezi-editor-source-btn' key="source" onClick={toggleSource}>
                                HTML Source
                            </button>,
                        ]}
                        wrapperStyle={{ display: showSource ? 'none' : 'block' }}
                    /> */}
                    <div className='ezi-editor-source'>
                        {/* <button className="ezi-editor-code-btn" key="code" onClick={toggleSource} style={{ margin: '0 4px', cursor: 'pointer' }}>
                            Rich Text Editor
                        </button> */}
                        <textarea
                            value={htmlContent}
                            onChange={handleHtmlChange}
                            style={{ width: '100%', height: '300px', padding: '10px', border: '1px solid #F1F1F1' }}
                            placeholder="Edit HTML source code..."
                        />
                    </div>
                </>}
            </section>
        </React.Fragment>
    )
}
/* eslint-enable */
const mapStateToProps = state => {
    return {
        vendorInfo: state.app.vendorInfo
    }
}
export default connect(mapStateToProps)(ManagerPractices);