import React, { useState, useEffect } from 'react';
import './Dashboard.scss';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Axios from "utility/Axios";
import configURL from 'config/config';

const Dashboard = (props) => {
    let navigate = useNavigate();
    const [translate] = useTranslation();
    const [CompanyCount, setCompanyCount] = useState();
    const [templateCount, setTemplateCount] = useState();
    const [CompanyList, setCompanyList] = useState();
    const [TemplateList, setTemplateList] = useState();
    const RECENT_TEMPLATE_CLASSES = ['published', 'unpublished', 'draft'];

    /**
     * Get partner widget counts
     */
    const getDashboardInitials = () => {
        Axios.post(configURL.partner_dashboard_companies, {}).then(response => {
            if (response.data.success !== undefined && response.data.success) {
                setCompanyCount(response.data.result.total_companies)
                setCompanyList(response.data.result.recent_companies ? response.data.result.recent_companies : []);
            } else {
                toast.warn(response.data.message)
            }
        }).catch(err => {
            console.log(err);
        })
    }
    /**
     * Get template widget counts
     */
    const getTemplateInitials = () => {
        Axios.post(configURL.partner_dashboard_template, {}).then(response => {
            if (response.data.success !== undefined && response.data.success) {
                setTemplateCount(response.data.results)
                setTemplateList(response.data.results.recent_templates ? response.data.results.recent_templates : []);
            } else {
                toast.warn(response.data.message)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    /* eslint-disable */
    useEffect(() => {
        let successStatus = sessionStorage.getItem('isShow');
        if (successStatus !== null && successStatus === 'true') {
            toast.success(translate('LoggedIn.1'), {
            });
        }
        sessionStorage.removeItem('isShow');
    }, [])

    useEffect(() => {
        getDashboardInitials();
    }, [])
    useEffect(() => {
        getTemplateInitials();
    }, [])
    /* eslint-enable */
    return (
        <React.Fragment>
            <section className="Page-PartnerAdminDashboard">

                <div className="admin-dashboard-header">
                    <div className="user-welcome-wrap">
                        <span className="user-welcome-text">Partner Admin Dashboard! </span>
                    </div>
                </div>


                <div className="common-template-card-wrap">
                    <div className="common-template-card" onClick={() => navigate('/companies')}>
                        <div className="common-template-card-icon-wrap">
                            <span className="common-template-card-icon template_ic"></span>
                        </div>
                        <div className="common-template-card-text-wrap">
                            <span className="common-template-card-text">Total Companies</span>
                            <span className="common-template-count">{CompanyCount}</span>
                        </div>
                    </div>
                    <div className="common-template-card" onClick={() => {
                        navigate('/template-dashboard/category-templates', {
                            state: {category_id: "",
                            category_name: "All Categories",
                            template_status: "all",}
                        });
                    }}>
                        <div className="common-template-card-icon-wrap">
                            <span className="common-template-card-icon total_temaplte_ic"></span>
                        </div>
                        <div className="common-template-card-text-wrap">
                            <span className="common-template-card-text">Total Templates</span>
                            <span className="common-template-count">{templateCount && templateCount.total_templates}</span>
                        </div>
                    </div>
                    <div className="common-template-card" onClick={() => {
                        navigate('/template-dashboard/category-templates', {
                            state: {category_id: "",
                            category_name: "All Categories",
                            template_status: "published",}
                        });
                    }}>
                        <div className="common-template-card-icon-wrap">
                            <span className="common-template-card-icon publish_ic"></span>
                        </div>
                        <div className="common-template-card-text-wrap">
                            <span className="common-template-card-text">Published Templates</span>
                            <span className="common-template-count">{templateCount && templateCount.published_templates}</span>
                        </div>
                    </div>

                    <div className="common-template-card add-new-company" onClick={() => navigate('/add-company')}>
                        <div className="common-template-card-icon-wrap">
                            <span className="common-template-card-icon circle-plus"></span>
                        </div>
                        <div className="common-template-card-text-wrap">
                            <span className="common-template-card-text">Add</span>
                            <span className="new-company-text">New Company</span>
                        </div>
                    </div>
                </div>

                <div className="partner-vertical-card-main-wrap">
                    <div className="recent-template-card">
                        <div className="recent-template-card-header">Recent templates</div>
                        <div className="recent-template-card-body">
                            <ul>
                                {
                                    Array.isArray(TemplateList) && TemplateList.map((item, index) =>
                                        <li key={`recent-template${index}`} className={RECENT_TEMPLATE_CLASSES[index % 3]}>
                                            <span className="recent-template-index">{index + 1}</span>
                                            <span className="recent-template-name">{item.name}</span>
                                            <span className={`recent-template-status ${((item.ispublished === true) ? "Published" : "Unpublished").toLowerCase()}`}>{(item.ispublished === true) ? "Published" : "Unpublished"}</span>
                                            {/* <button type="button" className="recent-template-report">Analyse</button> */}
                                        </li>
                                    )
                                }
                            </ul>
                            {(TemplateList && !TemplateList.length) && <span className="no-card-data">No Recent Templates Available</span>}

                        </div>

                    </div>

                    <div className="recent-companies-card">
                        <div className="recent-companies-card-header">Recent companies </div>
                        <div className="recent-companies-card-body">
                            <ul>
                                {
                                    Array.isArray(CompanyList) && CompanyList.map((item, index) =>
                                        <li key={`recent-companies${index}`} >
                                            <img alt="" src={item.company_logo || require(`../assets/images/company-logo/company-logo.png`)} className="recent-companies-logo" />
                                            <span className="recent-companies-name">{item.name}</span>
                                            <span className="recent-companies-id">{item.created_at ? item.created_at.split(" ")[0] : ''}</span>
                                        </li>
                                    )
                                }
                            </ul>
                            {(CompanyList && !CompanyList.length) && <span className="no-card-data">No Recent Companies Available</span>}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default Dashboard; 